import React, { useMemo } from 'react';
import {
  useQueryParam_tagInstancePredictions_directoryId,
  useQueryParam_tagInstancePredictions_documentId,
  useQueryParam_tagInstancePredictions_entryTagId,
  useQueryParam_tag_tagId,
} from '../../../../controllers/useGlobalQueryParams';

import CodefyIcon from '../../../appLogo/codefyIcon';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import ImageIcon from '@material-ui/icons/Image';
import { PaneKeys } from '../paneKeys';
import PaneTitleBar from '../../paneTitleBar';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import { useOpenSavedSearchTermsDialog } from '../../../dialogs/edit/editTagSavedSearchTermsDialog';
import { usePaneActions } from '../../usePaneActions';
import { useTagInstancePredictionsList } from '../../../../controllers/api/subscriptions/taglists/tags/tagInstances/tagInstancesPredictionsList';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';
import { useUserSetting_assistantResults_snippetVariant } from '../../../../controllers/api/subscriptions/users/userSettings';

export default function TagInstancePredictionsPaneTitleBar() {
  const { t } = useTranslation();
  const paneActions = usePaneActions();

  const [tag_tagId] = useQueryParam_tag_tagId({ taglistType: 'annotation' });
  const [tagInstancePredictions_documentId] = useQueryParam_tagInstancePredictions_documentId();
  const [tagInstancePredictions_directoryId] = useQueryParam_tagInstancePredictions_directoryId();
  const [tagInstancePredictions_entryTagId] = useQueryParam_tagInstancePredictions_entryTagId();

  const { id: currentCaseId } = useCurrentCase();

  const { data: tag } = useTagsGet({
    tag_id: tag_tagId,
    case_id: currentCaseId,
  });

  const [
    userSetting_assistantResults_snippetVariant,
    set_userSetting_assistantResults_snippetVariant,
  ] = useUserSetting_assistantResults_snippetVariant();

  const { data: directory } = useDirectoriesGet(tagInstancePredictions_directoryId);
  const { data: document } = useDocumentsGet(tagInstancePredictions_documentId);
  const { data: entryTag } = useTagsGet({
    tag_id: tagInstancePredictions_entryTagId,
    case_id: currentCaseId,
  });

  const { data: resultsPages, isFetching } = useTagInstancePredictionsList({
    tag_id: tag_tagId,
    document_ids: tagInstancePredictions_documentId
      ? [tagInstancePredictions_documentId]
      : undefined,
    directory_ids: tagInstancePredictions_directoryId
      ? [tagInstancePredictions_directoryId]
      : undefined,
    entry_tag_ids: tagInstancePredictions_entryTagId
      ? [tagInstancePredictions_entryTagId]
      : undefined,
  });

  const resultsCount = resultsPages?.pages[0]?.results.length;

  const assistantScopeSuffix = useMemo(() => {
    if (tagInstancePredictions_documentId && document) {
      const name = document.path.entry_name;
      if (resultsCount === undefined && isFetching) {
        return t('assistantPane.reviewLoading', { name });
      }

      return t('assistantPane.reviewing', { name });
    }

    if (tagInstancePredictions_directoryId && directory) {
      const name = directory.path?.entry_name || directory.path?.project_name;
      if (resultsCount === undefined && isFetching) {
        return t('assistantPane.reviewLoading', { name });
      }

      return t('assistantPane.reviewing', { name });
    }

    if (tagInstancePredictions_entryTagId && entryTag) {
      const name = entryTag.name;
      if (resultsCount === undefined && isFetching) {
        return t('assistantPane.reviewLoading', { name });
      }
      return t('assistantPane.reviewing', { name });
    }

    return '';
  }, [
    tagInstancePredictions_documentId,
    document?.path.entry_name,
    directory?.path?.entry_name,
    directory?.path?.project_name,
    tagInstancePredictions_directoryId,
    tagInstancePredictions_entryTagId,
    resultsCount,
  ]);

  const openSavedSearchTermsDialog = useOpenSavedSearchTermsDialog();

  return (
    <PaneTitleBar
      icon={<CodefyIcon />}
      title={assistantScopeSuffix}
      paneKey={PaneKeys.tagInstancePredictions}
      onCollapseRightPane={() => {
        paneActions.closePanes([PaneKeys.annotationTag]);
      }}
      menuEntries={
        tag
          ? [
              {
                key: 'savedSearchTerms',
                text: t('useTagMenuEntries.savedSearchTerms'),
                icon: <FindReplaceIcon />,
                onClick: () => openSavedSearchTermsDialog({ tag_id: tag.id }),
              },
              {
                key: 'snippetVariant',
                text:
                  userSetting_assistantResults_snippetVariant === 'image'
                    ? t('snippetVariant.showText')
                    : t('snippetVariant.showImage'),
                icon:
                  userSetting_assistantResults_snippetVariant === 'image' ? (
                    <TextFieldsIcon />
                  ) : (
                    <ImageIcon />
                  ),
                onClick: () =>
                  set_userSetting_assistantResults_snippetVariant(
                    userSetting_assistantResults_snippetVariant === 'image' ? 'text' : 'image',
                  ),
              },
            ]
          : undefined
      }
    />
  );
}
