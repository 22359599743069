import axios from 'axios';
import { useQuery } from 'react-query';
import Codefy from '../../../../codefy';
import { fastApiDataSerializer } from '../../subscriptionHelpers';

export type SearchSnippetParams =
  | { document_id: Codefy.Objects.Document['id']; boxes: Codefy.Objects.Box[] }
  | { annotation_id: Codefy.Objects.Annotation['id']; color?: string }
  | undefined;

export type SearchSnippetResponse = string; // base64

export const SEARCH_SNIPPET_KEY_TYPE = 'searchSnippet';

export const searchSnippetKey = (
  params: SearchSnippetParams,
): [string, Record<string, any> | undefined] => [SEARCH_SNIPPET_KEY_TYPE, params];

export const useSearchSnippet = (params: SearchSnippetParams) =>
  useQuery(searchSnippetKey(params), async () => {
    //@ts-ignore
    if (!params.annotation_id && !(params.document_id && params.boxes)) return undefined;
    const { data } = await axios('/api/v1/search/snippet', {
      method: 'POST',
      data: fastApiDataSerializer(params || {}),
    });
    return data as SearchSnippetResponse;
  });
