import { COLORS, useGlobalStyles } from '../../../../../globalThemeSettings';
import React, { useMemo, useState } from 'react';

import Codefy from '../../../../../codefy';
import PaneContentLoading from '../../../paneContentLoading';
import { fastApiParamsSerializer } from '../../../../../controllers/api/subscriptionHelpers';

export const getAnnotationAreaPreviewImageSrc = (annotation: Codefy.Objects.Annotation): string =>
  `/api/v1/annotations/area_preview?` +
  fastApiParamsSerializer({
    document_id: annotation.path.document_id,
    page: annotation.boxes[0].page,
    xmin: annotation.boxes[0].xmin,
    xmax: annotation.boxes[0].xmax,
    ymin: annotation.boxes[0].ymin,
    ymax: annotation.boxes[0].ymax,
    color: annotation.tag_instances?.[0]?.tag_color || COLORS.defaultTagColor,
  });

export default function AnnotationAreaPreview({
  annotation,
  onClick,
}: {
  annotation: Codefy.Objects.Annotation;
  onClick: () => void;
}) {
  const globalClasses = useGlobalStyles();
  const [loaded, setLoaded] = useState<boolean>();
  const imageSrc = getAnnotationAreaPreviewImageSrc(annotation);
  const loaderStyle: React.CSSProperties = useMemo(() => ({ minHeight: '200px' }), []);

  return (
    <>
      {!loaded && (
        <div style={loaderStyle}>
          <PaneContentLoading />
        </div>
      )}
      <img
        src={imageSrc}
        className={globalClasses.snippetImage}
        onLoad={() => setLoaded(true)}
        onClick={onClick}
      />
    </>
  );
}
