import { Box, Grid, IconButton, Switch } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import React from 'react';
import { SnippetVariant } from '../../../controllers/api/subscriptions/users/userSettings';

export default function SnippetVariantSwitch({
  value,
  onChange,
}: {
  value: SnippetVariant;
  onChange: (value: SnippetVariant) => any;
}) {
  return (
    <Grid
      component="label"
      container
      alignItems="center"
      alignContent="center"
      spacing={1}
      onClick={() => onChange(value === 'image' ? 'text' : 'image')}>
      <Grid item></Grid>
      <Grid item>
        <IconButton size="small">
          <TextFieldsIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <Box mb={0}>
          <Switch size="small" color="primary" checked={value === 'image'} />
        </Box>
      </Grid>
      <Grid item>
        <IconButton size="small">
          <ImageIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
