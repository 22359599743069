import { ALERT_STYLE, COLORS, useGlobalStyles } from '../../../../globalThemeSettings';
import { Box, IconButton, Popover, Typography } from '@material-ui/core';
import React, { useMemo, useRef } from 'react';
import { bindContextMenu, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import {
  useIsPaneOpen,
  useQueryParam_taglist_id,
  useQueryParam_trashMode,
} from '../../../../controllers/useGlobalQueryParams';
import { useTrashModeIcons, useTrashModeStrings } from '../../../../hooks/useTrashModeVariants';

import { Alert } from '@material-ui/lab';
import CachedIcon from '@material-ui/icons/Cached';
import Codefy from '../../../../codefy';
import CommentsIcon from '../../../icons/commentsIcon';
import { CreateComment } from '../comments/createComment';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MoveEntryIcon from '../../../icons/moveEntry';
import { PaneKeys } from '../paneKeys';
import PdfViewerLeftIcon from '../../../icons/pdfViewerLeft';
import PdfViewerRightAddIcon from '../../../icons/pdfViewerRightAdd';
import PdfViewerRightIcon from '../../../icons/pdfViewerRight';
import PersonIcon from '@material-ui/icons/Person';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TagLabelsEditor from '../../../tagLabels/tagLabelsEditor';
import TaglistSelector from '../taglist/taglistSelector';
import clsx from 'clsx';
import { directoriesConvert } from '../../../../controllers/api/actions/directories/directoriesConvert';
import directoriesDownload from '../../../../controllers/api/actions/directories/directoriesDownload';
import { documentsDownload } from '../../../../controllers/api/actions/documents/documentsDownload';
import { entriesDuplicate } from '../../../../controllers/api/actions/entries/entriesDuplicate';
import { entriesTrash } from '../../../../controllers/api/actions/entries/entriesTrash';
import moment from 'moment';
import useCurrentCase from '../../../../hooks/useCurrentCase';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import useIsEveryone from '../../../../hooks/useIsEveryone';
import { useOpenCommentsDialog } from '../../../dialogs/commentsDialog';
import { useOpenMoveEntryDialog } from '../../../dialogs/moveEntryDialog';
import { useOpenRenameEntryDialog } from '../../../dialogs/rename/renameEntryDialog';
import { usePaneActions } from '../../usePaneActions';
import { useTranslation } from 'react-i18next';

// TODO: Add translations

/** Whatever is inside this wrapper will open a document context menu (rename, delete, ...) when
 * right-clicked */
export default function EntryContextMenuWrapper({
  entry,
  children,
  openOnLeftClick,
  hideOpenInParentDirectory,
}: {
  entry?: Codefy.Objects.Entry;
  children: React.ReactChild | React.ReactChildren;
  /** Used when we are wrapping a button that is supposed to open the context menu when clicked
   * (with the left mouse button) */
  openOnLeftClick?: boolean;
  hideOpenInParentDirectory?: boolean;
}) {
  const { t } = useTranslation();
  const paneActions = usePaneActions();
  const isEveryone = useIsEveryone();

  const [trashMode] = useQueryParam_trashMode();
  const trashModeStrings = useTrashModeStrings();
  const trashModeIcons = useTrashModeIcons();

  const globalClasses = useGlobalStyles();

  const { id: currentCaseId } = useCurrentCase();

  const operationsTagsEnabled = useFeatureFlag('operations_tags');

  const [entryTaglist_id] = useQueryParam_taglist_id({
    taglistType: 'entry',
  });

  const contextMenuState = usePopupState({ variant: 'popover', popupId: 'entryContextMenu' });

  const isPdfViewerOpen = useIsPaneOpen(PaneKeys.pdfViewer);
  const isPdfViewer2Open = useIsPaneOpen(PaneKeys.pdfViewer2);

  const ref = useRef();

  const openRenameEntryDialog = useOpenRenameEntryDialog();
  const openMoveEntryDialog = useOpenMoveEntryDialog();
  const openCommentsDialog = useOpenCommentsDialog();

  const onMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    contextMenuState.close();
  };

  const [contextState, setContextState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>({ mouseX: null, mouseY: null });

  const onOpenComments = () => {
    if (!entry?.id) return;
    openCommentsDialog({ entry_id: entry.id });
  };

  const onOpenDocumentLeft = () => {
    if (!entry?.document) return;
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer,
      params: {
        pdfViewer_documentId: entry.document.id,
        pdfViewer_page: 1,
      },
      reset: true,
    });
  };

  const onOpenDocumentRight = () => {
    if (!entry?.document) return;
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer2,
      params: {
        pdfViewer2_documentId: entry.document.id,
        pdfViewer2_page: 1,
      },
      reset: true,
    });
  };

  const onDownloadDirectory = () => {
    if (!entry?.directory?.path?.entry_name || !entry?.directory?.id) return;
    directoriesDownload(entry?.directory?.path?.entry_name, entry.directory.id);
  };

  const onDownloadDocument = async () => {
    if (!entry?.document) return;
    documentsDownload(entry.document);
  };

  /** Directories currently cannot be duplicated */
  const onDuplicateEntry = () => {
    if (!entry?.id || !(entry?.document || entry.taglist)) return;

    entriesDuplicate({ entry_id: entry.id });
  };

  const onConvert = async () => {
    if (!entry?.directory) return;
    directoriesConvert({ directory_id: entry.directory.id });
  };

  const onOpenParentDirectory = () => {
    if (!entry?.path?.directory_id) return;
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.entriesList,
      params: { entriesList_directoryId: entry.path.directory_id },
    });
  };

  const menuAnchorPosition =
    contextState.mouseY !== null && contextState.mouseX !== null
      ? { top: contextState.mouseY, left: contextState.mouseX }
      : undefined;

  const userHasWritePermission = entry?.path?.write_permission;
  const userHasAddPermission = entry?.path?.add_permission;

  const divProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > = useMemo(
    () => ({
      ...bindContextMenu(contextMenuState),
      onContextMenu: (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (!contextMenuState.isOpen) {
          setContextState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          });
        }

        contextMenuState.open(ref.current);
      },
      onClick: (event: React.MouseEvent<HTMLDivElement>) => {
        if (!openOnLeftClick || contextMenuState.isOpen) return;

        event.stopPropagation();
        event.preventDefault();
        if (!contextMenuState.isOpen) {
          setContextState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          });
        }

        contextMenuState.open(ref.current);
      },
    }),
    [contextMenuState.isOpen],
  );

  const menu = (
    /* Popover instead of Menu is used because Menu comes with a lot of automatic behaviour that is
    incompatible with our custom functionality, esp the TagLabelsEditor */
    <Popover
      {...bindMenu(contextMenuState)}
      onClose={contextMenuState.close}
      anchorReference="anchorPosition"
      anchorPosition={menuAnchorPosition}
      disablePortal
      onClick={onMenuClick}
      style={{ zIndex: 99990 }}>
      <Box mt={1} mb={1}>
        {trashMode ? (
          <MenuItem
            data-e2e-id="context-menu-restore-entry"
            onClick={() => {
              if (!entry?.id) return;
              entriesTrash({ entry_ids: [entry?.id], trashed: false });
            }}>
            <ListItemIcon>{trashModeIcons('restore')}</ListItemIcon>
            {trashModeStrings('restore')}
          </MenuItem>
        ) : (
          <>
            {userHasAddPermission && entry?.path?.project_id && (
              <>
                <Box m={2} mt={2} mb={1}>
                  <Typography className={globalClasses.subheading}>
                    {t('entriesList.contextMenu.comment')}
                  </Typography>
                </Box>
                {entry.comments.length === 0 ? (
                  <Box m={1} onClick={(event) => event.stopPropagation()} width={400}>
                    <Box width="100%">
                      <CreateComment
                        createCommentParams={{
                          type: 'entry',
                          entry_id: entry.id,
                          case_id: currentCaseId,
                        }}
                        alwaysFull
                      />
                    </Box>
                  </Box>
                ) : (
                  <MenuItem onClick={onOpenComments}>
                    <Box ml={-0.4} mb={-0.5}>
                      <ListItemIcon>
                        <IconButton
                          size="small"
                          disabled
                          style={{ color: COLORS.primary, backgroundColor: 'none' }}>
                          <CommentsIcon commentsCount={entry.comments.length} />
                        </IconButton>
                      </ListItemIcon>
                    </Box>
                    {t('comments.viewComments')}
                  </MenuItem>
                )}
              </>
            )}

            {operationsTagsEnabled && userHasAddPermission && (
              <Box mt={2}>
                <Box m={2} mt={2} mb={1}>
                  <Typography className={globalClasses.subheading}>
                    {t('entriesList.contextMenu.projectManagement')}
                  </Typography>
                </Box>
                {entry?.path?.directory_id && (
                  <TaglistSelector
                    directoryId={entry?.path?.directory_id}
                    taglistType="entry"
                    menu
                  />
                )}
              </Box>
            )}

            {entry?.path?.project_id &&
              operationsTagsEnabled &&
              userHasAddPermission &&
              entryTaglist_id && (
                <>
                  <Box>
                    <TagLabelsEditor
                      taglistType="entry"
                      project_id={entry?.path?.project_id}
                      entry={entry}
                      onClose={contextMenuState.close}
                      source="entryContextMenuWrapper"
                      indent
                      maxHeight={100}
                    />
                  </Box>
                </>
              )}

            {/* {entry?.directory && (
          <>
            <Box m={2} mt={3} mb={1}>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.review')}
              </Typography>
            </Box>
            <MenuItem onClick={onOpenAssistantForDirectory}>
              <ListItemIcon>
                <IconButton size="small">
                  <CodefyIcon />
                </IconButton>
              </ListItemIcon>
              {t('useTaglistMenuEntries.showAnalysis')}
            </MenuItem>
          </>
        )}

        {entry?.document && (
          <>
            <Box m={2} mt={3} mb={1}>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.review')}
              </Typography>
            </Box>
            <MenuItem onClick={onOpenAssistantForDocument}>
              <ListItemIcon>
                <IconButton size="small">
                  <CodefyIcon />
                </IconButton>
              </ListItemIcon>
              {t('useTaglistMenuEntries.showAnalysis')}
            </MenuItem>
          </>
        )} */}

            {entry?.document && isPdfViewerOpen && !isPdfViewer2Open && (
              <>
                <Box m={2} mt={3} mb={1}>
                  <Typography className={globalClasses.subheading}>
                    {t('entriesList.contextMenu.view')}
                  </Typography>
                </Box>
                <MenuItem
                  data-e2e-id="entry-contextmenu-openInSecondaryViewer"
                  onClick={onOpenDocumentRight}>
                  <ListItemIcon>
                    <PdfViewerRightAddIcon />
                  </ListItemIcon>
                  {t('pdfViewer2.openRightAdd')}
                </MenuItem>
              </>
            )}

            {entry?.document && isPdfViewerOpen && isPdfViewer2Open && (
              <>
                <Box m={2} mt={3} mb={1}>
                  <Typography className={globalClasses.subheading}>
                    {t('entriesList.contextMenu.view')}
                  </Typography>
                </Box>
                <MenuItem
                  data-e2e-id="entry-contextmenu-openInPrimaryViewer"
                  onClick={onOpenDocumentLeft}>
                  <ListItemIcon>
                    <PdfViewerLeftIcon />
                  </ListItemIcon>
                  {t('pdfViewer2.openLeft')}
                </MenuItem>
                <MenuItem
                  data-e2e-id="entry-contextmenu-openInSecondaryViewer"
                  onClick={onOpenDocumentRight}>
                  <ListItemIcon>
                    <PdfViewerRightIcon />
                  </ListItemIcon>
                  {t('pdfViewer2.openRight')}
                </MenuItem>
              </>
            )}

            {userHasWritePermission && !isEveryone && (
              <>
                <Box m={2} mt={3} mb={1}>
                  <Typography className={globalClasses.subheading}>
                    {t('entriesList.contextMenu.edit')}
                  </Typography>
                </Box>
              </>
            )}

            {!hideOpenInParentDirectory && (
              <>
                <MenuItem
                  data-e2e-id="entry-contextmenu-openParentDirectory"
                  onClick={onOpenParentDirectory}>
                  <ListItemIcon>
                    <FolderOpenIcon />
                  </ListItemIcon>
                  {t('entriesList.contextMenu.openParentDirectory')}
                </MenuItem>
              </>
            )}

            {userHasWritePermission && !isEveryone && (
              <MenuItem
                data-e2e-id="entry-contextmenu-rename"
                onClick={openRenameEntryDialog({ entryId: entry?.id })}
                disabled={isEveryone || !userHasWritePermission}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                {t('entriesList.contextMenu.rename')}
              </MenuItem>
            )}

            {userHasWritePermission && !isEveryone && (
              <MenuItem
                data-e2e-id="entry-contextmenu-move"
                onClick={openMoveEntryDialog({ entryId: entry?.id })}
                disabled={isEveryone || !userHasWritePermission}>
                <ListItemIcon>
                  <MoveEntryIcon />
                </ListItemIcon>
                {t('entriesList.contextMenu.move')}
              </MenuItem>
            )}

            {userHasAddPermission && !isEveryone && (entry?.document || entry?.taglist) && (
              <MenuItem data-e2e-id="entry-contextmenu-duplicate" onClick={onDuplicateEntry}>
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                {t('entriesList.contextMenu.duplicate')}
              </MenuItem>
            )}

            {userHasWritePermission &&
              !isEveryone &&
              entry?.directory &&
              entry?.mimetype == 'inode/case' && (
                <MenuItem data-e2e-id="entry-contextmenu-convert-to-directory" onClick={onConvert}>
                  <ListItemIcon>
                    <CachedIcon />
                  </ListItemIcon>
                  {t('entriesList.contextMenu.convertToDirectory')}
                </MenuItem>
              )}

            {userHasWritePermission &&
              !isEveryone &&
              entry?.directory &&
              entry?.mimetype == 'inode/directory' && (
                <MenuItem data-e2e-id="entry-contextmenu-convert-to-case" onClick={onConvert}>
                  <ListItemIcon>
                    <CachedIcon />
                  </ListItemIcon>
                  {t('entriesList.contextMenu.convertToCase')}
                </MenuItem>
              )}

            {entry?.document && (
              <MenuItem
                data-e2e-id="entry-contextmenu-download-document"
                onClick={onDownloadDocument}>
                <ListItemIcon>
                  <GetAppIcon />
                </ListItemIcon>
                {t('entriesList.contextMenu.download')}
              </MenuItem>
            )}

            {entry?.directory && (
              <MenuItem
                data-e2e-id="entry-contextmenu-download-directory"
                onClick={onDownloadDirectory}>
                <ListItemIcon>
                  <GetAppIcon />
                </ListItemIcon>
                {t('entriesList.contextMenu.downloadDirectory')}
              </MenuItem>
            )}

            {userHasWritePermission && !isEveryone && entry?.id && (
              <MenuItem
                data-e2e-id="context-menu-delete-entry"
                onClick={() => {
                  entriesTrash({ entry_ids: [entry?.id] });
                }}>
                <ListItemIcon>{trashModeIcons('trash')}</ListItemIcon>
                {trashModeStrings('trash')}
              </MenuItem>
            )}

            <Box m={2} mt={3} mb={1}>
              <Typography className={globalClasses.subheading}>
                {t('entriesList.contextMenu.created')}
              </Typography>
            </Box>

            <MenuItem button={false}>
              <ListItemIcon>
                <PersonIcon color="disabled" />
              </ListItemIcon>
              <Typography className={globalClasses.textLight}>
                {entry?.created_by ? (
                  <a
                    className={clsx(globalClasses.textLight, globalClasses.underlineOnHover)}
                    href={entry?.created_by?.email ? `mailto:${entry?.created_by?.email}` : '#'}>
                    {entry?.created_by?.name}
                  </a>
                ) : (
                  <span className={clsx(globalClasses.textLight, globalClasses.underlineOnHover)}>
                    {t('deletedUser')}
                  </span>
                )}
              </Typography>
            </MenuItem>
            <MenuItem button={false}>
              <ListItemIcon>
                <ScheduleIcon color="disabled" />
              </ListItemIcon>
              <Typography className={globalClasses.textLight}>
                {moment(entry?.created_at).format('YYYY-MM-DD, HH:mm:ss')}
              </Typography>
            </MenuItem>

            {entry?.path?.write_permission === false && entry.owned_by && !isEveryone && (
              <Box m={2}>
                <Alert severity="info" style={ALERT_STYLE}>
                  {t('entriesList.contextMenu.managedBy')}{' '}
                  <a className={globalClasses.linkLight} href={`mailto:${entry.owned_by.email}`}>
                    {entry.owned_by.name}
                  </a>
                  .
                </Alert>
              </Box>
            )}
          </>
        )}
      </Box>
    </Popover>
  );

  if (!entry) return <>{children}</>;

  return (
    <div {...divProps} ref={ref.current}>
      {menu}
      <div id="children">{children}</div>
    </div>
  );
}
