import React, { useMemo } from 'react';
import {
  useQueryParam_search_directoryId,
  useQueryParam_search_documentId,
  useQueryParam_search_query,
} from '../../../../../controllers/useGlobalQueryParams';

import ImageIcon from '@material-ui/icons/Image';
import { PaneKeys } from '../../paneKeys';
import PaneTitleBar from '../../../paneTitleBar';
import SearchCodefyIcon from '../../../../icons/searchCodefy';
import SearchIcon from '../../../../icons/search';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { useDirectoriesGet } from '../../../../../controllers/api/subscriptions/directories/directoriesGet';
import { useDocumentsGet } from '../../../../../controllers/api/subscriptions/documents/documentsGet';
import useFacets from './useFacets';
import { useSearchFacets } from '../../../../../controllers/api/subscriptions/search/searchFacets';
import useSearchParams from '../useSearchParams';
import { useSearchSearch } from '../../../../../controllers/api/subscriptions/search/searchSearch';
import { useTranslation } from 'react-i18next';
import { useUserSetting_searchPane_snippetVariant } from '../../../../../controllers/api/subscriptions/users/userSettings';

export const SEARCH_SUGGEST_LIMIT = 5;

function useSearchResultsCount({ facets_key }: { facets_key?: string }) {
  const { data: facets } = useSearchFacets({ facets_key });
  if (!facets_key) return null;
  return facets?.num_parts;
}

export default function SearchPaneTitleBar() {
  const [search_query] = useQueryParam_search_query();
  const { t } = useTranslation();

  const [search_directoryId] = useQueryParam_search_directoryId();
  const [search_documentId] = useQueryParam_search_documentId();

  const [
    userSettingSearchPaneSnippetVariant,
    set_userSettingSearchPaneSnippetVariant,
  ] = useUserSetting_searchPane_snippetVariant();

  const { data: directory } = useDirectoriesGet(search_directoryId);
  const { data: document } = useDocumentsGet(search_documentId);

  const searchScopeSuffix = useMemo(() => {
    if (search_documentId && document)
      return t('searchPane.inDocument', { name: document.path.entry_name });
    if (search_directoryId && directory)
      return t('searchPane.inFolder', {
        name: directory.path?.entry_name || directory.path?.project_name,
      });
    return '';
  }, [
    search_documentId,
    document?.path.entry_name,
    directory?.path?.entry_name,
    directory?.path?.project_name,
    search_directoryId,
  ]);

  const searchParams = useSearchParams({ variant: 'searchPane' });
  const { data: searchPages } = useSearchSearch(searchParams);

  const searchResultsCount = useSearchResultsCount({
    facets_key: searchPages?.pages[0]?.facets_key,
  });

  const facetmenuEntries = useFacets(searchPages?.pages[0]?.facets_key);

  facetmenuEntries.push({
    key: 'snippetVariant',
    text:
      userSettingSearchPaneSnippetVariant === 'image'
        ? t('snippetVariant.showText')
        : t('snippetVariant.showImage'),
    icon: userSettingSearchPaneSnippetVariant === 'image' ? <TextFieldsIcon /> : <ImageIcon />,
    onClick: () =>
      set_userSettingSearchPaneSnippetVariant(
        userSettingSearchPaneSnippetVariant === 'image' ? 'text' : 'image',
      ),
  });

  const title =
    searchParams.method === 'trigram' ? (
      <span>{`${searchResultsCount ?? '...'} ${t('searchMain.aiResultsFor', {
        count: searchResultsCount || 0,
      })} ${searchScopeSuffix}`}</span>
    ) : (
      <span>{`${searchResultsCount ?? '...'} ${t('searchMain.resultsFor', {
        count: searchResultsCount || 0,
      })} "${search_query}"${searchScopeSuffix}`}</span>
    );

  return (
    <PaneTitleBar
      icon={searchParams.method === 'trigram' ? <SearchCodefyIcon /> : <SearchIcon />}
      title={title}
      paneKey={PaneKeys.search}
      menuEntries={facetmenuEntries}
    />
  );
}
