import { Box, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  useQueryParam_uploadBatch_status,
  useQueryParam_uploadBatch_uuid,
} from '../../../../controllers/useGlobalQueryParams';

import CancelIcon from '@material-ui/icons/Cancel';
import Codefy from '../../../../codefy';
import DeleteIcon from '@material-ui/icons/Delete';
import { DocumentStatus } from '../../../../controllers/api/actions/documents/documentsUpload';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import GenericContextMenu from '../../../menus/genericContextMenu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { PaneKeys } from '../paneKeys';
import { UploadBatchesListItemStatus } from './UploadBatchesListItemStatus';
import { getUploadBatchesListItemIcon } from './getUploadBatchesListItemIcon';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import useHover from '../../../../hooks/useHover';
import { useOpenCancelUploadBatchUploadsDialog } from '../../../dialogs/delete/cancelUploadsBatchUploads';
import { useOpenDeleteUploadBatchDialog } from '../../../dialogs/delete/deleteUploadBatchDialog';
import { usePaneActions } from '../../usePaneActions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUploadBatchesName } from './useUploadBatchesName';

export function UploadBatchesListItem({
  uploadBatch,
}: {
  uploadBatch: Codefy.Objects.UploadBatch;
}) {
  const [hoverRef, isHovered] = useHover();
  const { t } = useTranslation();
  const paneActions = usePaneActions();
  const globalClasses = useGlobalStyles();
  const [uploadBatch_uuid] = useQueryParam_uploadBatch_uuid();
  const [uploadBatch_status] = useQueryParam_uploadBatch_status();

  const uploadsOfThisBatch = useSelector((state: Codefy.State) =>
    state.uploads.filter((upload) => upload.upload_batch_uuid === uploadBatch.upload_batch_uuid),
  );

  const openDeleteUploadBatchDialog = useOpenDeleteUploadBatchDialog();
  const openCancelUploadBatchUploadsDialog = useOpenCancelUploadBatchUploadsDialog();

  const done = uploadBatch.num_documents === uploadBatch.num_documents_processed;

  useEffect(() => {
    if (uploadBatch_uuid !== uploadBatch.upload_batch_uuid) return;
    if (done && uploadBatch_status !== DocumentStatus.processed) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.uploadBatch,
        params: { uploadBatch_status: DocumentStatus.processed },
      });
    }
  }, [done]);

  const [expanded, setExpanded] = useState(
    uploadBatch.upload_batch_uuid === uploadBatch_uuid || uploadsOfThisBatch.length > 0,
  );

  const onClick = () => setExpanded(!expanded);

  const primaryText = useUploadBatchesName(uploadBatch);

  const uploading = uploadBatch.num_documents_uploading && uploadBatch.num_documents_uploading > 0;

  const menuEntries = [
    {
      key: 'cancelUploads',
      text: t('uploadBatch.cancelUploads'),
      icon: <CancelIcon />,
      onClick: openCancelUploadBatchUploadsDialog({
        upload_batch_uuid: uploadBatch.upload_batch_uuid,
      }),
      disabled: !uploading,
    },
    {
      key: 'delete',
      text: t('uploadBatch.deleteAllDocuments'),
      icon: <DeleteIcon />,
      onClick: openDeleteUploadBatchDialog({
        upload_batch_uuid: uploadBatch.upload_batch_uuid,
      }),
    },
  ];

  return (
    <>
      <GenericContextMenu menuEntries={menuEntries}>
        <ListItem button divider onClick={onClick} innerRef={hoverRef}>
          <ListItemIcon className={globalClasses.narrowListItemIcon}>
            {expanded ? <ExpandLessIcon /> : getUploadBatchesListItemIcon(uploadBatch)}
          </ListItemIcon>
          <ListItemText primary={primaryText} />

          {menuEntries.length > 0 && (
            <GenericContextMenu menuEntries={menuEntries} openOnLeftClick>
              <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                style={isHovered ? {} : { opacity: 0 }}>
                <MoreVertIcon />
              </IconButton>
            </GenericContextMenu>
          )}
        </ListItem>
        <div>
          {expanded && (
            <Box ml={2}>
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.error_format_not_supported}
                uploadBatchNumKey="num_documents_error_format_not_supported"
                description={t('uploadBatch.statusItemTexts.errorFormatNotSupported')}
                type="error"
                clickable
              />
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.uploading}
                uploadBatchNumKey="num_documents_uploading"
                description={t('uploadBatch.statusItemTexts.uploading')}
                type="working"
                clickable
              />
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.uploading_cancelled}
                uploadBatchNumKey="num_documents_uploading_cancelled"
                description={t('uploadBatch.statusItemTexts.uploading_cancelled')}
                type="error"
                clickable
              />
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.error_upload_failed}
                uploadBatchNumKey="num_documents_error_upload_failed"
                description={t('uploadBatch.statusItemTexts.uploadFailed')}
                type="error"
                clickable
              />
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.error_quota_exceeded}
                uploadBatchNumKey="num_documents_error_quota_exceeded"
                description={t('uploadBatch.statusItemTexts.errorQuotaExceeded')}
                type="error"
                clickable
              />
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.storing}
                uploadBatchNumKey="num_documents_storing"
                description={t('uploadBatch.statusItemTexts.storing')}
                type="working"
              />
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.unprocessed}
                uploadBatchNumKey="num_documents_unprocessed"
                description={t('uploadBatch.statusItemTexts.unprocessed')}
                type="working"
              />
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.unprocessed_ocr}
                uploadBatchNumKey="num_documents_unprocessed_ocr"
                description={t('uploadBatch.statusItemTexts.unprocessed_ocr')}
                secondary={t('uploadBatch.statusItemTexts.unprocessed_ocr_secondary')}
                type="working"
              />
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.failed}
                uploadBatchNumKey="num_documents_failed"
                description={t('uploadBatch.statusItemTexts.failed')}
                type="error"
                clickable
              />
              <UploadBatchesListItemStatus
                uploadBatch={uploadBatch}
                documentStatus={DocumentStatus.processed}
                uploadBatchNumKey="num_documents_processed"
                description={t('uploadBatch.statusItemTexts.processed')}
                type="success"
                clickable
              />
            </Box>
          )}
        </div>
      </GenericContextMenu>
    </>
  );
}
