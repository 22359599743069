import { ALERT_STYLE, useGlobalStyles } from '../../../globalThemeSettings';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { KeyboardEventHandler, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import Codefy from '../../../codefy';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import { GenericDialog } from '../genericDialog';
import { TagListTag } from '../../panes/paneTypes/taglist/tagListTag';
import { tagsSavedSearchTerms } from '../../../controllers/api/actions/taglists/tags/tagsSavedSearchTerms';
import { useBetween } from 'use-between';
import { useTaglistsGet } from '../../../controllers/api/subscriptions/taglists/taglistsGet';
import { useTagsGet } from '../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';

type DialogSavedSearchTermsStateType = { tag_id: Codefy.Objects.Tag['id'] };

const useDialogSavedSearchTermsState = () => useState<DialogSavedSearchTermsStateType>();

const useSharedDialogSavedSearchTermsState = () => useBetween(useDialogSavedSearchTermsState);

export const EditTagSavedSearchTermsDialog = () => {
  const [state, setState] = useSharedDialogSavedSearchTermsState();
  const { data: tag } = useTagsGet({ tag_id: state?.tag_id });
  const { data: taglist } = useTaglistsGet({ taglist_id: tag?.taglist_id });

  const onClose = () => setState(undefined);
  const onSave = async (newSavedSearchTerms: Codefy.Objects.Tag['saved_search_terms']) => {
    if (!tag?.id) return;

    return await tagsSavedSearchTerms({ tag_id: tag?.id, saved_search_terms: newSavedSearchTerms });
  };

  if (!state || !tag || !taglist) return null;

  return (
    <SavedSearchTermsDialogRenderer
      tag={tag}
      onSave={onSave}
      onClose={onClose}
      writePermission={taglist.path.write_permission}
      owner={taglist.owned_by}
    />
  );
};

export const SavedSearchTermsDialogRenderer = ({
  tag,
  onSave,
  onClose,
  writePermission,
  owner,
}: {
  tag: Codefy.Objects.Tag;
  onSave: (
    newSavedSearchTerms: Codefy.Objects.Tag['saved_search_terms'],
  ) => Promise<Codefy.Objects.Tag | undefined>;
  onClose: () => void;
  writePermission: boolean;
  owner: Codefy.Objects.Taglist['owned_by'];
}) => {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const [savedSearchTerms, setSavedSearchTerms] = useState(tag.saved_search_terms || []);
  const [input, setInput] = useState<string>('');

  const onRemoveSearchTerm = async (searchTerm: string) => {
    const newSavedSearchTerms = savedSearchTerms.filter((term) => term !== searchTerm);
    const result = await onSave(newSavedSearchTerms);
    if (result) {
      setSavedSearchTerms(newSavedSearchTerms);
    }
  };

  const onAddSearchTerm = async (searchTerm: string) => {
    if (savedSearchTerms.includes(searchTerm)) return;
    const newSavedSearchTerms = [...savedSearchTerms, searchTerm];
    const result = await onSave(newSavedSearchTerms);
    if (result) {
      setSavedSearchTerms(newSavedSearchTerms);
      setInput('');
    }
  };

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      onAddSearchTerm(input);
    }
  };

  return (
    <GenericDialog title={t('useTagMenuEntries.savedSearchTerms')} onClose={onClose} open>
      <Box>
        <TagListTag tag={tag} writePermission={false} addPermission={false} hideButtons />
        <List dense>
          {savedSearchTerms?.map((savedSearchTerm) => (
            <ListItem key={savedSearchTerm} className={globalClasses.listEntry}>
              <ListItemIcon>
                <FindReplaceIcon />
              </ListItemIcon>
              <ListItemText primary={savedSearchTerm} />
              {writePermission && (
                <ListItemSecondaryAction>
                  <IconButton size="small" onClick={() => onRemoveSearchTerm(savedSearchTerm)}>
                    <ClearIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}

          {writePermission && (
            <ListItem className={globalClasses.listEntry}>
              <ListItemIcon>
                <FindReplaceIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <TextField
                    autoFocus
                    fullWidth
                    placeholder={t('useTagMenuEntries.addNew')}
                    value={input}
                    onKeyDown={onKeyDown}
                    onChange={(event) => setInput(event.target.value)}
                  />
                }
              />
              <ListItemSecondaryAction>
                <IconButton size="small" onClick={() => onAddSearchTerm(input)}>
                  <AddIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>

        {!writePermission && owner && (
          <Alert severity="info" style={ALERT_STYLE}>
            {t('taglist.managedBy')}{' '}
            <a className={globalClasses.linkLight} href={`mailto:${owner.email}`}>
              {owner.name}
            </a>
            .
          </Alert>
        )}

        <Typography className={globalClasses.textLight}>
          {t('useTagMenuEntries.predictionHelp', { postProcess: 'markdown-jsx' })}
        </Typography>
      </Box>
    </GenericDialog>
  );
};

export const useOpenSavedSearchTermsDialog = () => {
  const [, setState] = useSharedDialogSavedSearchTermsState();
  return (state: DialogSavedSearchTermsStateType) => setState(state);
};
