import Codefy from '../../../../../../codefy';
import { SearchSearchReturnType } from '../../../search/searchSearch';
import { useCreateInfiniteQuery } from '../../../../subscriptionHelpers';

export const TAG_INSTANCE_PREDICTIONS_LIST_URL =
  '/api/v1/taglists/tags/instances/predict_saved_search_terms';

const TAG_INSTANCE_PREDICTIONS_LIST_KEY_TYPE = 'tagInstancesPredictions';

export type TagInstancePredictionsListParams = {
  tag_id?: Codefy.Objects.Tag['id'];
  document_ids?: Codefy.Objects.Document['id'][];
  directory_ids?: Codefy.Objects.Directory['id'][];
  entry_tag_ids?: Codefy.Objects.Tag['id'][];
  // FIXME adapt to final API parameters
  window_size?: number;
  min_confidence?: number;
};

export const tagInstancePredictionsListKey = (
  params: TagInstancePredictionsListParams,
): [string, TagInstancePredictionsListParams] => [TAG_INSTANCE_PREDICTIONS_LIST_KEY_TYPE, params];

export const useTagInstancePredictionsList = (params: TagInstancePredictionsListParams) =>
  useCreateInfiniteQuery<SearchSearchReturnType>(
    tagInstancePredictionsListKey(params),
    10,
    TAG_INSTANCE_PREDICTIONS_LIST_URL,
    'GET',
    params,
    'results',
    true,
    !params.tag_id,
  );
