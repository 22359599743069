import { Button, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import {
  useQueryParam_entriesList_directoryId,
  useQueryParam_pdfViewer_documentId,
} from '../../../../controllers/useGlobalQueryParams';

import Codefy from '../../../../codefy';
import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import InfiniteScroll from 'react-infinite-scroll-component';
import PaneContentLoading from '../../paneContentLoading';
import { PaneKeys } from '../paneKeys';
import React from 'react';
import SearchNoResults from './searchNoResults';
import TextSearchResult from './searchResultsItem';
import { useDispatch } from 'react-redux';
import { useEntriesList } from '../../../../controllers/api/subscriptions/entries/entriesList';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import { useHistory } from 'react-router';
import { useMeasure } from 'react-use';
import { usePaneActions } from '../../usePaneActions';
import useSearchParams from './useSearchParams';
import { useSearchSearch } from '../../../../controllers/api/subscriptions/search/searchSearch';
import { useTranslation } from 'react-i18next';
import { useUserSetting_searchPane_snippetVariant } from '../../../../controllers/api/subscriptions/users/userSettings';

const useStyles = makeStyles(() => ({
  virtuoso: { width: '100%', height: '100%', overflow: 'auto' },
  padded: { padding: '30px 30px 0px 30px' },
}));

export default function SearchResultsPaneContent() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const paneActions = usePaneActions();
  const dispatch = useDispatch();
  const [pdfViewer_documentId] = useQueryParam_pdfViewer_documentId(1);
  const [entriesList_directoryId] = useQueryParam_entriesList_directoryId();

  const [userSetting_searchPane_snippetVariant] = useUserSetting_searchPane_snippetVariant();

  const searchParams = useSearchParams({ variant: 'searchPane' });
  const {
    data: searchPages,
    fetchNextPage: searchFetchMore,
    hasNextPage: searchCanFetchMore,
    failureCount: searchFailureCount,
    isLoading: searchLoading,
  } = useSearchSearch(searchParams);
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const search = searchPages?.pages[0];
  const searchResults =
    searchPages?.pages.reduce((acc: Codefy.Objects.SearchResult[], cur) => {
      for (const result of cur.results) {
        acc.push(result);
      }
      return acc;
    }, []) || [];

  const {
    data: entriesPages,
    failureCount: entriesFailureCount,
    isLoading: entriesLoading,
  } = useEntriesList({
    directory_ids: searchParams.directory_ids,
    name: searchParams.query,
    recursive: true,
  });
  const entries = entriesPages?.pages[0].entries || [];

  const goBackButton = (
    <Button
      onClick={() => history.goBack()}
      style={{ marginLeft: '10px' }}
      variant="outlined"
      size="small"
      color="primary">
      {t('searchFilter.goBack')}
    </Button>
  );

  if (searchFailureCount > 0 || entriesFailureCount > 0) {
    return <div className={classes.padded}>Error :( {goBackButton}</div>;
  }

  if (!searchLoading && !entriesLoading && searchResults.length === 0 && entries.length === 0) {
    return <SearchNoResults />;
  }

  try {
    if (search?.debug) {
      const message = 'QUERY DEBUG – SEARCH (normal, not insta)';
      const params = search.debug.map((e: string) => e.split('\n'));
      console.debug(message, params);
    }
  } catch (error) {
    console.error(error);
  }

  const renderResult = (index: number) => {
    if (!searchResults[index]) return <span></span>;
    const searchResult = searchResults[index];
    return (
      <TextSearchResult
        searchResult={searchResult}
        index={index}
        key={'pane-document-' + searchResult.document.id + 'part-' + searchResult.part_id}
        uniqueId={'pane-document-' + searchResult.document.id + 'part-' + searchResult.part_id}
        isInstasearchResult={false}
        defaultSnippetVariant={userSetting_searchPane_snippetVariant || 'image'}
      />
    );
  };

  const renderEntry = (entry: Codefy.Objects.Entry) => {
    return (
      <ListItem
        button
        divider
        selected={
          (entry.document && pdfViewer_documentId === entry.document.id) ||
          (entry.directory && entriesList_directoryId === entry.directory.id)
        }
        onClick={() => {
          if (entry.document) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.document.path.directory_id },
              reset: true,
            });
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.pdfViewer,
              params: { pdfViewer_documentId: entry.document.id },
              reset: true,
            });
          }
          if (entry.directory) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.directory.id },
            });
          }
          if (entry.taglist) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.taglist.path.directory_id },
              reset: true,
            });
            if (entry.taglist.type === 'annotation') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.annotationTaglist,
                params: { annotationTaglist_id: entry.taglist.id },
              });
            } else if (entry.taglist.type === 'entry') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.entryTaglist,
                params: { entryTaglist_id: entry.taglist.id },
              });
            }
          }
          dispatch({ type: 'setSearch', open: false });
        }}>
        <ListItemIcon className={globalClasses.narrowListItemIcon}>
          {<EntryFileTypeIcon entryMimetype={entry.mimetype} style={{ marginRight: '3px' }} />}
        </ListItemIcon>
        <ListItemText primary={<b>{entry.name}</b>} />
      </ListItem>
    );
  };

  if (searchLoading && !search?.results) {
    return <PaneContentLoading />;
  }

  return (
    <div className={classes.virtuoso} ref={ref}>
      <InfiniteScroll
        className={classes.virtuoso}
        dataLength={searchResults.length || 0}
        next={searchFetchMore}
        height={height - 1}
        hasMore={!!searchCanFetchMore}
        loader={<PaneContentLoading />}>
        {
          /** When searching INSIDE a document, the user does not expect other documents to appear
           * as search results. **/
          !searchParams.document_ids && entries.map((entry) => renderEntry(entry))
        }
        {searchResults.map((_, index) => renderResult(index))}
      </InfiniteScroll>
    </div>
  );
}
