import {
  AlignmentType,
  BorderStyle,
  Document,
  ExternalHyperlink,
  Footer,
  Header,
  HeadingLevel,
  Packer,
  PageNumber,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import {
  TAGS_LIST_URL,
  TagsListParams,
  TagsListReturnType,
} from '../../subscriptions/taglists/tags/tagsList';
import {
  TAG_INSTANCES_LIST_URL,
  TagInstancesListParams,
  TagInstancesReturnType,
} from '../../subscriptions/taglists/tags/tagInstances/tagInstancesList';

import { COLORS } from '../../../../globalThemeSettings';
import Codefy from '../../../../codefy';
import { TAGLISTS_GET_URL } from '../../subscriptions/taglists/taglistsGet';
import { TFunction } from 'i18next';
import axios from 'axios';
import { fastApiParamsSerializer } from '../../subscriptionHelpers';
import getTagsHierarchy from '../../../../components/panes/paneTypes/tag/getTagsHierarchy';
import moment from 'moment';

const downloadBlob = async (blob: Blob, fileName: string) => {
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const taglistsExportPrettyWord = async ({
  taglist_id,
  case_id,
  projectTitle,
  projectID,
  createdAt,
  createdBy,
  summary,
  includeOperationsTags,
  includeTagDescriptions,
  includeTaggedContent,
  includeAnnotationTagInstanceComments,
  t,
}: {
  taglist_id: Codefy.Objects.Taglist['id'];
  case_id?: Codefy.Objects.Directory['id'];
  projectTitle: string;
  projectID?: string;
  createdAt: string;
  createdBy: string;
  summary: string;
  includeOperationsTags: boolean;
  includeTagDescriptions: boolean;
  includeTaggedContent: boolean;
  includeAnnotationTagInstanceComments: boolean;
  t: TFunction;
}) => {
  const { data: taglist } = await axios.get<Codefy.Objects.Taglist>(TAGLISTS_GET_URL, {
    params: { taglist_id },
    paramsSerializer: fastApiParamsSerializer,
  });

  const { data: tags } = await axios.get<TagsListReturnType>(TAGS_LIST_URL, {
    params: { tag_ids: taglist.tags.map((t) => t.id), case_id, order_by: 'name' } as TagsListParams,
    paramsSerializer: fastApiParamsSerializer,
  });

  const { data: tagInstances } = await axios.get<TagInstancesReturnType>(TAG_INSTANCES_LIST_URL, {
    params: {
      taglist_ids: [taglist_id],
      case_ids: case_id ? [case_id] : undefined,
    } as TagInstancesListParams,
    paramsSerializer: fastApiParamsSerializer,
  });

  tags.tags = tags.tags.map((tag) => {
    const currentTagInstances: Codefy.Objects.TagInstance[] = [];
    tagInstances.tag_instances.forEach((tagInstance) => {
      if (tagInstance.tag_id == tag.id) {
        currentTagInstances.push(tagInstance);
      }
    });
    tag.tagInstances = currentTagInstances;
    return tag;
  });

  const tagHierarchy = getTagsHierarchy(tags.tags);

  const FONT = 'Calibri';

  const BORDERS_EMPTY = {
    top: { size: 0, color: '#000000', style: BorderStyle.NONE },
    bottom: { size: 0, color: '#000000', style: BorderStyle.NONE },
    left: { size: 0, color: '#000000', style: BorderStyle.NONE },
    right: { size: 0, color: '#000000', style: BorderStyle.NONE },
  };

  const NEW_PART_SPACING = {
    before: 200,
  };

  const HEADER = new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 500,
        },
        style: HeadingLevel.HEADING_1,
        text: taglist.path.entry_name || '',
      }),
    ],
  });

  const FOOTER = new Footer({
    children: [
      new Table({
        borders: BORDERS_EMPTY,
        rows: [
          new TableRow({
            children: [
              new TableCell({
                borders: BORDERS_EMPTY,
                children: [
                  new Paragraph({
                    style: 'Footer',
                    children: [
                      new TextRun('Version '),
                      new TextRun({ text: moment().format('YYYY-MM-DD'), bold: true }),
                    ],
                  }),
                ],
                width: { size: 70, type: WidthType.PERCENTAGE },
              }),
              new TableCell({
                borders: BORDERS_EMPTY,
                children: [
                  new Paragraph({
                    style: 'Footer',
                    alignment: AlignmentType.CENTER,
                    children: [new TextRun({ text: projectTitle, bold: true })],
                  }),
                ],
                width: { size: 100, type: WidthType.PERCENTAGE },
              }),
              new TableCell({
                borders: BORDERS_EMPTY,
                children: [
                  new Paragraph({
                    style: 'Footer',
                    alignment: AlignmentType.RIGHT,
                    children: [
                      new TextRun('Seite '),
                      new TextRun({ children: [PageNumber.CURRENT], bold: true }),
                      new TextRun(' von '),
                      new TextRun({ children: [PageNumber.TOTAL_PAGES], bold: true }),
                    ],
                  }),
                ],
                width: { size: 50, type: WidthType.PERCENTAGE },
              }),
            ],
          }),
        ],
      }),
    ],
  });

  const INTRO: (Paragraph | Table)[] = [];
  INTRO.push(new Paragraph({ children: [new TextRun('Projektname')] }));
  INTRO.push(new Paragraph({ style: HeadingLevel.HEADING_1, text: projectTitle }));

  if (projectID) {
    INTRO.push(new Paragraph({ spacing: { before: 100 }, children: [new TextRun('Project-ID')] }));
    INTRO.push(new Paragraph({ style: HeadingLevel.HEADING_1, text: projectID }));
  }

  INTRO.push(
    new Paragraph({
      spacing: NEW_PART_SPACING,
      children: [
        new TextRun('Report erstellt durch: '),
        new TextRun({ text: createdBy, bold: true }),
      ],
    }),
  );
  INTRO.push(
    new Paragraph({
      children: [
        new TextRun('Report erstellt am: '),
        new TextRun({ text: createdAt, bold: true }),
        new TextRun({ text: ' Uhr', bold: true }),
      ],
    }),
  );

  if (summary) {
    INTRO.push(
      new Paragraph({
        spacing: NEW_PART_SPACING,
        style: HeadingLevel.HEADING_1,
        text: 'Zusammenfassung / Vorbemerkung',
      }),
    );
    const SUMMARY_TABLE_BORDER_STYLE = { size: 10, color: '#003366', style: BorderStyle.SINGLE };
    INTRO.push(
      new Table({
        borders: {
          top: SUMMARY_TABLE_BORDER_STYLE,
          left: SUMMARY_TABLE_BORDER_STYLE,
          right: SUMMARY_TABLE_BORDER_STYLE,
          bottom: SUMMARY_TABLE_BORDER_STYLE,
        },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                width: { size: 100, type: WidthType.PERCENTAGE },
                margins: { top: 100, left: 100, right: 100, bottom: 100 },
                children: [
                  new Paragraph({
                    text: summary,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    );
  }

  const tagInstanceToParagraphs = (tagInstance: Codefy.Objects.TagInstance): Paragraph[] => {
    if (!('annotation' in tagInstance)) return [new Paragraph({})];

    const paragraphs = [
      new Paragraph({
        bullet: { level: 0 },
        spacing: NEW_PART_SPACING,
        children: [
          new TextRun({ text: 'Dokument: ', bold: true, italics: true }),
          new TextRun({
            text: tagInstance.annotation.path.entry_name,
            bold: true,
            italics: true,
          }),
          new TextRun({ text: 'Seite ', italics: true, break: 1 }),
          new TextRun({
            text: tagInstance.annotation.boxes[0].page.toString(),
            italics: true,
          }),
          new TextRun({ text: ' | ' }),
          new ExternalHyperlink({
            child: new TextRun({
              italics: true,
              text: 'Diese Stelle in Codefy öffnen',
              style: 'Hyperlink',
            }),
            link: `${window.location.protocol}//${window.location.hostname}/share/annotation/${tagInstance.annotation.id}`,
          }),
          new TextRun({ text: '"', italics: true, color: '555555', break: 1 }),
          new TextRun({ text: tagInstance.annotation.selected_text, italics: true }),
          new TextRun({ text: '"', italics: true, color: '555555' }),
        ],
      }),
    ];

    if (includeAnnotationTagInstanceComments) {
      tagInstance.comments.forEach((comment) => {
        paragraphs.push(
          new Paragraph({
            bullet: { level: 1 },
            spacing: NEW_PART_SPACING,
            children: [
              new TextRun({ text: 'Kommentar von ' }),
              new TextRun({
                text: comment.created_by
                  ? `${comment.created_by.name} (${comment.created_by.email})`
                  : t('deletedUser'),
                bold: true,
              }),
              new TextRun({ text: ' am ' }),
              new TextRun({
                text: moment(comment.created_at).format('DD.MM.YYYY'),
                bold: true,
              }),
              new TextRun({ text: ' um ' }),
              new TextRun({
                text: moment(comment.created_at).format('HH:mm') + ' Uhr',
                bold: true,
              }),
              new TextRun(':'),
              new TextRun({ text: comment.text, break: 1 }),
            ],
          }),
        );
      });
    }

    return paragraphs;
  };

  const FLATTENED_TAGS_WITH_TAG_INSTANCES: Paragraph[] = tagHierarchy.reduce(
    (acc, firstLevelTag) => {
      const processTagRecursively = (tag: Codefy.Objects.Tag, depth = 0) => {
        const style =
          depth === 0
            ? HeadingLevel.HEADING_1
            : depth === 1
            ? HeadingLevel.HEADING_2
            : HeadingLevel.HEADING_3;

        acc.push(
          new Paragraph({
            spacing: NEW_PART_SPACING,
            style,
            text: tag.name,
          }),
        );

        if (includeTagDescriptions && tag.description) {
          acc.push(new Paragraph({ text: tag.description }));
        }

        if (
          includeOperationsTags &&
          tag.annotation_tag_instances &&
          tag.annotation_tag_instances.length > 0
        ) {
          const children: TextRun[] = [];
          tag.annotation_tag_instances.forEach((annotation_tag_instance) => {
            children.push(
              new TextRun({
                shading: {
                  type: ShadingType.SOLID,
                  fill: annotation_tag_instance.tag_color?.substring(1) || COLORS.defaultTagColor,
                  color: annotation_tag_instance.tag_color?.substring(1) || COLORS.defaultTagColor,
                },
                text: annotation_tag_instance.tag_name,
                color: COLORS.tagLabelForegroundColor.substring(1),
              }),
            );
            children.push(new TextRun({ text: ' ' }));
          });
          acc.push(new Paragraph({ children }));
        }

        if (includeTaggedContent) {
          tag.tagInstances?.forEach((tagInstance) => {
            tagInstanceToParagraphs(tagInstance).forEach((paragraph) => {
              acc.push(paragraph);
            });
          });
        }

        tag.childTags?.forEach((childTag) => {
          processTagRecursively(childTag, depth + 1);
        });
      };

      processTagRecursively(firstLevelTag);

      return acc;
    },
    [] as Paragraph[],
  );

  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: 'Heading1',
          name: 'Heading 1',
          basedOn: 'Normal',
          next: 'Normal',
          run: {
            font: FONT,
            size: 28,
            bold: true,
            color: '003366',
          },
          paragraph: {
            spacing: { line: 1.5 * 240 },
          },
        },
        {
          id: 'Heading2',
          name: 'Heading 2',
          basedOn: 'Normal',
          next: 'Normal',
          run: {
            font: FONT,
            size: 24,
            bold: true,
            color: '003366',
          },
          paragraph: {
            spacing: { line: 1.5 * 240 },
          },
        },
        {
          id: 'Heading3',
          name: 'Heading 3',
          basedOn: 'Normal',
          next: 'Normal',
          run: {
            font: FONT,
            size: 22,
            bold: true,
            color: '003366',
          },
          paragraph: {
            spacing: { line: 1.5 * 240 },
          },
        },
        {
          id: 'Normal',
          name: 'Normal',
          basedOn: 'Normal',
          run: {
            font: FONT,
            size: 22,
          },
          paragraph: {
            spacing: { line: 1.5 * 240 },
          },
        },
        {
          id: 'Footer',
          name: 'Footer',
          basedOn: 'Normal',
          run: {
            font: FONT,
            size: 18,
          },
          paragraph: {
            spacing: { line: 1.5 * 240 },
          },
        },
      ],
    },
    sections: [
      {
        headers: {
          default: HEADER,
        },
        footers: {
          default: FOOTER,
        },
        children: [...INTRO, ...FLATTENED_TAGS_WITH_TAG_INSTANCES],
      },
    ],
  });

  const blob = await Packer.toBlob(doc);

  const fileName =
    [projectID, projectTitle, taglist.path.entry_name].filter((e) => e).join(' - ') + '.docx';

  downloadBlob(blob, fileName);
};
