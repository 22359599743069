import { COLORS, useGlobalStyles } from '../../../../../globalThemeSettings';
import React, { MouseEventHandler, useMemo } from 'react';

import Codefy from '../../../../../codefy';
import PaneContentLoading from '../../../paneContentLoading';
import { useSearchSnippet } from '../../../../../controllers/api/subscriptions/search/searchSnippet';

export default function AnnotationImage({
  annotation,
  onClick,
}: {
  annotation: Codefy.Objects.Annotation;
  onClick: MouseEventHandler<HTMLImageElement>;
}) {
  const globalClasses = useGlobalStyles();
  const loaderStyle: React.CSSProperties = useMemo(() => ({ minHeight: '300px' }), []);

  const { data: imageData, isLoading } = useSearchSnippet({
    annotation_id: annotation.id,
    color: annotation.tag_instances?.[0]?.tag_color || COLORS.defaultTagColor,
  });

  return (
    <>
      {isLoading ? (
        <div style={loaderStyle}>
          <PaneContentLoading />
        </div>
      ) : (
        <img
          className={globalClasses.snippetImage}
          src={`data:image/png;base64,${imageData}`}
          onClick={onClick}
        />
      )}
    </>
  );
}
