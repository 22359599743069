import Codefy from '../../../../codefy';
import Cookies from 'universal-cookie';
import { SESSION_COCKIE_KEY } from '../../../../components/layout';
import axios from 'axios';
import { handleActionError } from '../actionHelpers';

const cookies = new Cookies();

export const userLogout = () => {
  cookies.remove(SESSION_COCKIE_KEY);
  return async () => {
    try {
      const config: Codefy.API.Users.Logout.RequestConfig = {
        url: '/api/v1/users/logout',
        method: 'GET',
      };
      await axios(config);
    } catch (error) {
      handleActionError(error?.response?.data);
    } finally {
      window.location.href = '/';
    }
  };
};
