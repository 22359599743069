import { BOX_SHADOWS, useGlobalStyles } from '../../globalThemeSettings';
import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  Paper,
  PaperProps,
  Typography,
} from '@material-ui/core';
import React, { Children, ReactNode } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const style = { zIndex: 999 };
const paperProps = { style: { boxShadow: BOX_SHADOWS.medium, zIndex: 999 } };
const backdropProps = { style: { opacity: 0.25, backgroundColor: 'white' } };

export function GenericDialog({
  title,
  children,
  onClose,
  ...rest
}: DialogProps & { title: string; children: ReactNode }) {
  const globalClasses = useGlobalStyles();

  return (
    <Dialog
      fullWidth
      disableRestoreFocus
      style={style}
      PaperComponent={PaperComponent}
      PaperProps={paperProps}
      BackdropProps={backdropProps}
      /* Before setting this to >0 make sure that states are kept during the entire duration of the
      component, otherwise when closing the dialog, the text saying "Create case" might change to
      "Create folder" while the fade out transition is going on (just a few hundred milliseconds,
      but it might look "weird" to the user) */
      transitionDuration={0}
      onClose={onClose}
      {...rest}>
      <Box m={2} ml={3} mr={3} maxHeight={'calc(100vh - 200px)'}>
        <Box
          display="flex"
          alignItems="left"
          id="draggable-dialog-title"
          className={globalClasses.moveCursor}>
          <Box mb={2} flexGrow={1}>
            <Typography className={globalClasses.subheading}>{title}</Typography>
          </Box>
          <Box mt={-1} mr={-2}>
            <IconButton size="small" onClick={(event) => onClose?.(event, 'backdropClick')}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
        {Children.map(children, (child, index) => {
          if (index === 0) return child;
          return (
            <>
              <Box mt={1.5} mb={1.5}>
                <div className={globalClasses.visibleDivider} />
              </Box>
              {child}
            </>
          );
        })}
      </Box>
    </Dialog>
  );
}
