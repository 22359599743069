import 'react-resizable/css/styles.css';

import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  useQueryParam_focusedPane,
  useQueryParam_panes,
  useQueryParam_trashMode,
} from '../../controllers/useGlobalQueryParams';

import { CaseIdChangeListener } from '../listeners/CaseIdChangeListener';
import DialogsRenderer from '../dialogs/dialogsRenderer';
import { FollowSearchScopeListener } from '../listeners/FollowSearchScopeListener.tsx';
import { Modal } from '../old_modals/Modal';
import { Navbar } from '../navbar/navbar';
import Pane from './pane';
import { PaneKeys } from './paneTypes/paneKeys';
import { PanesViewportDragScrollHandler } from './panesViewportDragScrollHandler';
import { ROUTE_LOGIN } from '../../controllers/routes/routes';
import { Redirect } from 'react-router';
import UploadsListener from './paneTypes/uploadBatches/uploadsButton';
import clsx from 'clsx';
import { paneTypes } from './paneTypes/paneTypes';
import { useGlobalStyles } from '../../globalThemeSettings';
import useIsEveryone from '../../hooks/useIsEveryone';
import { usePaneActions } from './usePaneActions';
import useSessionRecorder from '../../hooks/useSessionRecorder';

export const PANES_VIEWPORT_ELEMENT_ID = 'panesViewport';

export const DEFAULT_PANES: PaneKeys[] = [PaneKeys.projectsList];

export const useStyles = makeStyles(() => ({
  paneViewport: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    paddingTop: '5px',
    paddingBottom: '10px',
    paddingRight: '25px',
    minHeight: '75vh',
  },
  firstPane: {
    marginLeft: '6px',
  },
  paneContent: {
    height: 'calc(100vh - 190px)',
    overflow: 'hidden',
  },
  collapsedPane: {
    transform: 'rotateZ(90deg) translate(0, -100px)',
    transformOrigin: 'top left',
  },
  collapsedPaneContent: {
    /** Using display: none instead of unmounting the pane content is very important so that the
     * state of the pane (e.g. scroll position in a PDF or search results list) is preserved */
    display: 'none',
    height: '0px',
  },
  dragHandle: {
    zIndex: 99,
    flex: '0 0 auto',
    height: '100%',
    width: '3px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '3px',
    paddingRight: '3px',
    marginRight: '-3px',
    marginLeft: '-3px',
    backgroundColor: '#00000000',
    cursor: 'col-resize',
    '&:hover': {
      backgroundColor: '#00000040',
    },
    borderRadius: '200px',
  },
  toolbar: {
    // necessary for content to be below app bar
    height: '55px',
  },
}));

/** Scroll to focused pane (once, on startup) */
const useScrollToFocusedPaneOnStartup = () => {
  const paneActions = usePaneActions();
  const [focusedPane] = useQueryParam_focusedPane();
  useEffect(() => {
    if (focusedPane) paneActions.focusPane(focusedPane as PaneKeys);
  }, []);
};

export default function PanesViewport() {
  const [panes, set_panes] = useQueryParam_panes();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const isEveryone = useIsEveryone();
  const [trashMode] = useQueryParam_trashMode();

  useSessionRecorder();
  useScrollToFocusedPaneOnStartup();

  /* Set default panes if no panes */
  useEffect(() => {
    if (!panes || (panes?.length === 0 && !isEveryone)) {
      set_panes(DEFAULT_PANES);
    }
  }, [panes]);

  const loginRouteWithNextUrl = ROUTE_LOGIN + '?nextUrl=' + window.location.href;

  if (!panes && isEveryone) return <Redirect to={loginRouteWithNextUrl} />;
  if (!panes) return null;

  const filteredPaneTypes = paneTypes
    .filter((pane) => panes.includes(pane.key))
    .filter((pane) => !(pane.needsLogin && isEveryone));

  if (filteredPaneTypes.length === 0 && isEveryone) return <Redirect to={loginRouteWithNextUrl} />;

  return (
    <div className={clsx(trashMode && globalClasses.trashOverlay)}>
      <Modal />
      <DialogsRenderer />

      <PanesViewportDragScrollHandler>
        <Navbar />

        <UploadsListener />
        <CaseIdChangeListener />
        <FollowSearchScopeListener />

        <div className={classes.toolbar} />

        <Grid container spacing={0}>
          <div className={clsx(classes.paneViewport)} id={PANES_VIEWPORT_ELEMENT_ID}>
            {filteredPaneTypes.map((pane) => (
              <Pane key={pane.key} pane={pane} />
            ))}
            <div style={{ flex: '0 0 auto', width: '50px' }}></div>
          </div>
        </Grid>
      </PanesViewportDragScrollHandler>
    </div>
  );
}
