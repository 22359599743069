import stringify from 'json-stable-stringify';
import { QueryKey } from 'react-query';
import Codefy from '../../../../codefy';
import { useCreateInfiniteQuery } from '../../subscriptionHelpers';

export type SearchSearchRequestParams = {
  query: string;
  directory_ids?: Codefy.Objects.Directory['id'][];
  recursive?: boolean;
  document_ids?: Codefy.Objects.Document['id'][];
  languages?: Codefy.Objects.LanguageType[];
  text_snippet_len?: number;
  offset?: number;
  limit?: number;
  attributes?: any;
  /** Used by the backend for statistics */
  autocomplete?: boolean;
  method?: 'insta' | 'trigram';
};

export type SearchSearchReturnType = {
  query: string;
  results: Codefy.Objects.SearchResult[];
  num_results: number;
  facets_key?: string;
  method: Codefy.Objects.SearchMethod;
  debug?: Codefy.Objects.SearchDebug;
  offset: number;
};

export const SEARCH_SEARCH_KEY_TYPE = 'searchSearch';

export const searchSearchKey = (params: any): QueryKey => [
  SEARCH_SEARCH_KEY_TYPE,
  stringify(params),
];

export const useSearchSearch = (params: SearchSearchRequestParams, keepPreviousData?: boolean) =>
  useCreateInfiniteQuery<SearchSearchReturnType>(
    searchSearchKey(params),
    10,
    '/api/v1/search/search',
    'POST',
    { ...params, query: params.query.trim() },
    'results',
    keepPreviousData,
    !params.query,
  );
