import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { isEmailValid, isPasswordValid } from './validation';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Codefy from '../../codefy';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import NoAuthScreenWrapper from './noAuthScreenWrapper';
import PaneContentLoading from '../panes/paneContentLoading';
import PasswordHelperText from './passwordHelperText';
import Slide from '@material-ui/core/Slide';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { handleActionError } from '../../controllers/api/actions/actionHelpers';
import { useQueryParam_nextUrl } from '../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';
import { userLogin } from '../../controllers/api/actions/user/userLogin';
import { userRegister } from '../../controllers/api/actions/user/userRegister';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
      overflow: 'hidden',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    bottomButtons: {
      paddingTop: theme.spacing(3),
    },
    alternativeLink: {
      textDecoration: 'none',
      fontWeight: 'bold',
      '&:hover': { textDecoration: 'underline' },
    },
    agreeContainer: { display: 'flex', alignItems: 'center', marginTop: '10px' },
  }),
);

const formTypes = {
  login: 'login',
  register: 'register',
};

type NoAuthFormType = keyof typeof formTypes;

/** A screen allowing the user to register a new account or log in with an existing
 * one. */
export const LoginAndRegister = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [query] = useQueryParams({ form: StringParam });
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isIAgree, setIsIAgree] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loginOrRegister, setLoginOrRegister] = useState(
    query?.form && formTypes[query.form as NoAuthFormType]
      ? formTypes[query.form as NoAuthFormType]
      : 'login',
  );
  const [showPassword1, setShowPassword1] = React.useState(false);

  const [loggingIn, setLoggingIn] = useState(false);
  const gotUser = useSelector((state: Codefy.State) => !!state.user?.email);

  const [nextUrl] = useQueryParam_nextUrl();

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownPassword1 = (event: any) => {
    event.preventDefault();
  };

  const [showPassword2, setShowPassword2] = React.useState(false);

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  const canSubmit =
    loginOrRegister === 'login'
      ? email && isEmailValid(email) && password
      : email &&
        isEmailValid(email) &&
        name &&
        password &&
        isPasswordValid(password) &&
        password === passwordConfirmation &&
        isIAgree;

  /** Runs the registration or login action */
  const onSubmit = () => {
    setLoggingIn(true);
    if (loginOrRegister === 'login') {
      dispatch(
        userLogin(
          { email, password },
          () => {
            if (nextUrl) {
              window.location.href = nextUrl;
            } else {
              window.location.pathname = '/';
            }
          },
          () => setLoggingIn(false),
        ),
      );
    } else if (loginOrRegister === 'register') {
      if (!isPasswordValid(password)) return;

      dispatch(userRegister({ email, password, name }))
        // @ts-ignore
        .then(() => {
          // take user to the confirmation email page
          history.push('/after-register', { email, password, name });
        }) // @ts-ignore
        .catch((error: any) => {
          handleActionError(error?.response?.data);
          setLoggingIn(false);
        });
    }
  };

  /** When the user presses the Enter key */
  const onPressEnter = (event: any) => {
    /* keyCode 13 is keyCode for the Enter key */
    if (event.keyCode === 13 && canSubmit) {
      onSubmit();
    }
  };

  /** Some common props for the <TextField> components to avoid duplicate code */
  const commonTextFieldProps: TextFieldProps = {
    variant: 'outlined',
    margin: 'normal',
    required: true,
    fullWidth: true,
    onKeyUp: onPressEnter,
  };

  if (loggingIn || !gotUser)
    return (
      <NoAuthScreenWrapper title={t('authScreen.signingIn')}>
        <Box m={2}>
          <PaneContentLoading />
        </Box>
      </NoAuthScreenWrapper>
    );

  return (
    <NoAuthScreenWrapper
      title={loginOrRegister === 'login' ? t('authScreen.signIn') : t('authScreen.createAccount')}>
      <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
        <Slide in direction="right">
          <TextField
            {...commonTextFieldProps}
            id="email"
            label={t('authScreen.email')}
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
            error={email !== '' && !isEmailValid(email)}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Slide>
        {loginOrRegister === 'register' && (
          <Slide in direction="right">
            <TextField
              {...commonTextFieldProps}
              id="name"
              label={t('authScreen.name')}
              name="name"
              autoComplete="name"
              type="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </Slide>
        )}
        <Slide in direction="right">
          <TextField
            {...commonTextFieldProps}
            label={t('authScreen.password')}
            name="password"
            type={showPassword1 ? 'text' : 'password'}
            id="password"
            autoComplete={loginOrRegister === 'register' ? 'new-password' : 'current-password'}
            error={loginOrRegister === 'register' && !!password && !isPasswordValid(password)}
            helperText={
              loginOrRegister === 'register' &&
              password &&
              passwordConfirmation &&
              !isPasswordValid(password) && <PasswordHelperText password={password} />
            }
            variant="outlined"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}>
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Slide>
        {loginOrRegister === 'register' && (
          <Slide in direction="right">
            <div>
              <TextField
                {...commonTextFieldProps}
                label={t('authScreen.confirmPassword')}
                name="confirm-password"
                type={showPassword2 ? 'text' : 'password'}
                autoComplete="new-password"
                id="confirm-password"
                value={passwordConfirmation}
                error={
                  password !== '' &&
                  passwordConfirmation !== '' &&
                  password !== passwordConfirmation
                }
                helperText={
                  password !== '' &&
                  passwordConfirmation !== '' &&
                  password !== passwordConfirmation && <PasswordHelperText />
                }
                onChange={(event) => setPasswordConfirmation(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}>
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <div className={classes.agreeContainer}>
                <Checkbox
                  checked={isIAgree}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setIsIAgree(checked);
                  }}
                  name="agree"
                  color="primary"
                />
                <label
                  htmlFor="agree"
                  dangerouslySetInnerHTML={{ __html: t('authScreen.agree') }}
                />
              </div>
            </div>
          </Slide>
        )}
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!canSubmit}
              className={classes.submit}
              onClick={onSubmit}>
              {loginOrRegister === 'login' ? t('authScreen.signIn') : t('authScreen.create')}
            </Button>
          </Grid>
        </Grid>
        <Grid container className={classes.bottomButtons} justify="space-between">
          <Grid item>
            <Link
              className={classes.alternativeLink}
              href="#"
              onClick={() => {
                if (loginOrRegister === 'login') {
                  setLoginOrRegister('register');
                } else if (loginOrRegister === 'register') {
                  setLoginOrRegister('login');
                }
              }}>
              {loginOrRegister === 'login'
                ? t('authScreen.createAccount')
                : t('authScreen.existingAccount')}
            </Link>
          </Grid>
          <Grid item>
            <Link className={classes.alternativeLink} component={RouterLink} to="/reset-password">
              {t('authScreen.forgotPassword')}
            </Link>
          </Grid>
        </Grid>
      </form>
    </NoAuthScreenWrapper>
  );
};
