import React, { useState } from 'react';

import FolderIcon from '@material-ui/icons/Folder';
import GenericCreateDialog from './../genericCreateDialog';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import { projectsCreate } from '../../../controllers/api/actions/projects/projectsCreate';
import { red } from '@material-ui/core/colors';
import { taglistsCreate } from '../../../controllers/api/actions/taglists/taglistsCreate';
import { tagsCreate } from '../../../controllers/api/actions/taglists/tags/tagsCreate';
import { useBetween } from 'use-between';
import { usePaneActions } from '../../panes/usePaneActions';
import { useTranslation } from 'react-i18next';

type DialogCreateProjectStateType = {};

const useDialogCreateProjectState = () => useState<DialogCreateProjectStateType>();

const useSharedDialogCreateProjectState = () => useBetween(useDialogCreateProjectState);

export const CreateProjectDialog = () => {
  const { t } = useTranslation();
  const paneActions = usePaneActions();

  const [state, setState] = useSharedDialogCreateProjectState();

  const onClose = () => setState(undefined);

  const onCreate = async (name: string) => {
    if (!name) return;

    const project = await projectsCreate({ name });

    if (!project?.id) return;

    // Add some default taglists

    const contentTaglist = await taglistsCreate({
      directory_id: project?.directory_id,
      name: t('tagLabelsEditor.newContentTaglist'),
      type: 'annotation',
    });

    const entryTaglist = await taglistsCreate({
      directory_id: project?.directory_id,
      name: t('tagLabelsEditor.newEntryTaglist'),
      type: 'entry',
    });

    if (!contentTaglist) return;
    if (!entryTaglist) return;

    tagsCreate({ taglist_id: contentTaglist.id, name: 'Highlight' });
    tagsCreate({
      taglist_id: contentTaglist.id,
      name: t('tagLabelsEditor.important'),
      color: red['200'],
    });

    tagsCreate({ taglist_id: entryTaglist.id, name: 'Todo' });
    tagsCreate({
      taglist_id: entryTaglist.id,
      name: t('tagLabelsEditor.important'),
      color: red['200'],
    });

    /** Switch to the newly created project */
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.entriesList,
      params: { entriesList_directoryId: project.directory_id },
      reset: true,
    });
  };

  return (
    <GenericCreateDialog
      open={!!state}
      title={t('createDialog.newWorkspace')}
      icon={<FolderIcon />}
      onClose={onClose}
      onCreate={onCreate}
    />
  );
};

export const useOpenCreateProjectDialog = () => {
  const [, setState] = useSharedDialogCreateProjectState();
  return () => setState({});
};
