import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { COLORS, useGlobalStyles } from '../../../../../globalThemeSettings';
import React, { useState } from 'react';
import {
  SnippetVariant,
  useUserSetting_annotations_snippetVariant,
} from '../../../../../controllers/api/subscriptions/users/userSettings';

import AddCommentIcon from '../../../../icons/addComment';
import AnnotationAreaPreview from './annotationAreaPreview';
import AnnotationContextMenuWrapper from './annotationContextMenuWrapper';
import AnnotationImage from './annotationImage';
import Codefy from '../../../../../codefy';
import { GenericCommentsList } from '../../comments/commentsList';
import ImageIcon from '@material-ui/icons/Image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { NO_DRAG_SCROLL_CLASSNAME } from '../../../panesViewportDragScrollHandler';
import { PaneKeys } from '../../paneKeys';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import moment from 'moment';
import useHover from '../../../../../hooks/useHover';
import useIsEveryone from '../../../../../hooks/useIsEveryone';
import { usePaneActions } from '../../../usePaneActions';
import { useQueryParam_pdfViewer_highlightAnnotationWithId } from '../../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  annotation: {
    cursor: 'pointer',
    width: '100%',
  },
}));

export default function Annotation({
  annotation,
}: {
  annotation: Codefy.Objects.Annotation;
  noDivider?: boolean;
}) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const paneActions = usePaneActions();
  const [hoverRef, isHovered] = useHover();
  const isEveryone = useIsEveryone();

  const [showCreateComment, setShowCreateComment] = useState(false);

  const [userSettingAnnotationsSnippetVariant] = useUserSetting_annotations_snippetVariant();
  const [manualSnippetVariant, setManualSnippetVariant] = useState<SnippetVariant>();
  const snippetVariant = manualSnippetVariant || userSettingAnnotationsSnippetVariant;

  const [pdfViewer_highlightAnnotationWithId] = useQueryParam_pdfViewer_highlightAnnotationWithId(
    1,
  );

  const onOpenAnnotationLeft = () => {
    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer,
      params: {
        pdfViewer_documentId: annotation.path.document_id,
        pdfViewer_boxes: JSON.stringify(annotation.boxes),
        pdfViewer_page: annotation.boxes[0].page,
        pdfViewer_highlightAnnotationWithId: annotation.id,
      },
      reset: true,
    });
  };

  return (
    <div ref={hoverRef} className={NO_DRAG_SCROLL_CLASSNAME}>
      <ListItem
        onClick={onOpenAnnotationLeft}
        dense
        button
        selected={pdfViewer_highlightAnnotationWithId === annotation.id}>
        <Box width="100%">
          <Grid className={classes.annotation}>
            <ListItemText
              className={globalClasses.heading}
              primary={
                <Typography variant="body2">
                  <b>{annotation.path.directory_name || annotation.path.project_name}</b>
                  <Box
                    className={
                      isHovered ? globalClasses.floatRightVisible : globalClasses.floatRightHidden
                    }>
                    <Grid container wrap="nowrap">
                      <Grid item>
                        {snippetVariant === 'image' ? (
                          <Tooltip title={t('snippetVariant.showText') || ''}>
                            <IconButton
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation();
                                setManualSnippetVariant('text');
                              }}>
                              <TextFieldsIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title={t('snippetVariant.showImage') || ''}>
                            <IconButton
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation();
                                setManualSnippetVariant('image');
                              }}>
                              <ImageIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                      {!isEveryone && (
                        <Grid item>
                          <AnnotationContextMenuWrapper annotation={annotation} openOnLeftClick>
                            <IconButton edge="end" aria-label="delete" size="small">
                              <MoreVertIcon />
                            </IconButton>
                          </AnnotationContextMenuWrapper>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Typography>
              }
            />

            <ListItemText
              className={globalClasses.heading}
              primary={
                <Typography variant="body2">
                  <b>{annotation.path.entry_name}</b>{' '}
                  <span className={globalClasses.textLight}>
                    › {t('searchResult.page')} {annotation.boxes[0].page}
                  </span>
                </Typography>
              }
            />
          </Grid>
        </Box>
      </ListItem>
      <ListItem
        dense
        divider={
          (!annotation.tag_instances?.[0]?.comments ||
            annotation.tag_instances?.[0]?.comments.length === 0) &&
          !showCreateComment
        }>
        <Grid>
          <Grid item md={12}>
            {annotation.area ? (
              <AnnotationAreaPreview annotation={annotation} onClick={onOpenAnnotationLeft} />
            ) : snippetVariant === 'image' ? (
              <AnnotationImage annotation={annotation} onClick={onOpenAnnotationLeft} />
            ) : (
              <Typography
                variant="body2"
                align="justify"
                className={globalClasses.text}
                dangerouslySetInnerHTML={{
                  __html:
                    annotation.text_snippet?.replaceAll(
                      'class="hla"',
                      `style="background-color:${
                        annotation.tag_instances?.[0]?.tag_color || COLORS.defaultTagColor
                      };"`,
                    ) || '',
                }}
              />
            )}
          </Grid>

          <Grid item md={12}>
            <Box mt={1}>
              <span className={globalClasses.textLight}>
                {t('annotations.createdAtBy', {
                  at: moment(annotation.created_at).fromNow(),
                  by: annotation.created_by?.name || t('deletedUser'),
                })}
              </span>
            </Box>
          </Grid>

          <Grid item md={12}>
            <Box mt={1} mb={1}>
              {annotation.tag_instances?.[0]?.comments &&
              annotation.tag_instances?.[0]?.comments.length > 0 ? (
                <span />
              ) : showCreateComment ? (
                <Box mt={3} />
              ) : (
                <Button onClick={() => setShowCreateComment(!showCreateComment)}>
                  <Grid container alignContent="center">
                    <AddCommentIcon />

                    <Box ml={1} display="inline-block">
                      <span className={globalClasses.dialogButton}>
                        {t('annotations.comments.mentionInputPlaceholder')}
                      </span>
                    </Box>
                  </Grid>
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </ListItem>
      {((annotation.tag_instances?.[0]?.comments &&
        annotation.tag_instances?.[0]?.comments.length > 0) ||
        showCreateComment) && (
        <ListItem divider>
          <Box mt={-3}>
            <GenericCommentsList
              comments={annotation.tag_instances?.[0]?.comments || []}
              createCommentParams={{
                type: 'tag_instance',
                tag_instance_id: annotation.tag_instances?.[0].id,
              }}
            />
          </Box>
        </ListItem>
      )}
    </div>
  );
}
