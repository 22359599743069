import React, { useMemo } from 'react';

import Codefy from '../../../../codefy';
import CodefyIcon from '../../../appLogo/codefyIcon';
import EntryFileTypeIcon from '../entriesList/entryFileTypeIcon';
import { GenericNestableMenuEntry } from '../../../menus/genericNestableMenuEntry';
import { PaneKeys } from '../paneKeys';
import useCurrentProjectDirectoryId from '../../../../hooks/useCurrentProjectDirectoryId';
import { useDirectoriesGet } from '../../../../controllers/api/subscriptions/directories/directoriesGet';
import useDirectoryTaglists from '../../../../hooks/useDirectoryTaglists';
import { useDocumentsGet } from '../../../../controllers/api/subscriptions/documents/documentsGet';
import { usePaneActions } from '../../usePaneActions';
import { useTagsGet } from '../../../../controllers/api/subscriptions/taglists/tags/tagsGet';
import { useTranslation } from 'react-i18next';

/** The Codefy button, to be used in the pdf viewer, entries list and entry tag list pane title bars
 * */
export function TagInstancePredictionsSourcePaneTitleBarMenuEntry({
  directoryId,
  documentId,
  entryTagId,
}: {
  directoryId?: Codefy.Objects.Directory['id'];
  documentId?: Codefy.Objects.Document['id'];
  entryTagId?: Codefy.Objects.Tag['id'];
}): GenericNestableMenuEntry | undefined {
  const paneActions = usePaneActions();
  const { t } = useTranslation();

  const currentProjectDirectoryId = useCurrentProjectDirectoryId();

  const { data: document } = useDocumentsGet(documentId);
  const { data: directory } = useDirectoriesGet(directoryId);
  const { data: tag } = useTagsGet({ tag_id: entryTagId });

  const currentAnnotationTaglists = useDirectoryTaglists({
    taglistType: 'annotation',
    directoryId: document?.path.directory_id || directory?.id || currentProjectDirectoryId,
  });

  const title = useMemo(() => {
    return (
      document?.path.entry_name ||
      directory?.path?.entry_name ||
      directory?.path?.project_name ||
      tag?.name
    );
  }, [
    document?.path.entry_name,
    directory?.path?.entry_name,
    directory?.path?.project_name,
    tag?.name,
  ]);

  return {
    key: 'tagInstancePredictions',
    icon: <CodefyIcon />,
    text: t('useTaglistMenuEntries.showAnalysis', { name: title }),
    menuEntries: currentAnnotationTaglists.map((entry) => ({
      key: entry.id.toString(),
      icon: <EntryFileTypeIcon entryMimetype={'application/codefy.annotation-taglist'} />,
      text: entry.name,
      onClick: () => {
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.annotationTaglist,
          params: {
            annotationTaglist_id: entry.taglist?.id,
          },
        });
        paneActions.addOrUpdatePane({
          paneKey: PaneKeys.tagInstancePredictions,
          params: {
            tagInstancePredictions_directoryId: directoryId,
            tagInstancePredictions_documentId: documentId,
            tagInstancePredictions_entryTagId: entryTagId,
          },
          reset: true,
        });
      },
    })),
  };
}
