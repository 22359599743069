import {
  FaFileAlt,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFilePowerpoint,
  FaFileWord,
} from 'react-icons/fa';

import AnnotationTaglistIcon from '../../../icons/annotationTaglist';
import { Box } from '@material-ui/core';
import Codefy from '../../../../codefy';
import EntryTaglistIcon from '../../../icons/entryTaglist';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { FiMail } from 'react-icons/fi';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import React from 'react';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

/** Shows a icon depending on the file type (= mimetype) of the document. Word and PDF
 * files have a special color because they are so common, so it's easier on the eye to
 * distinguish them. A tooltip is shown when the user hover over the file icon so that
 * the user not familiar with the icons, e.g. for PDF, can still get the desired
 * information. */
export default function EntryFileTypeIcon(params: {
  entryMimetype?: Codefy.Objects.Entry['mimetype'];
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}) {
  // TODO: Maybe this can be done in a different way?
  /** It needs to be moved a little bit down so it's inline with the rest of the text */
  const style: React.CSSProperties = {
    marginBottom: '-2px',
    ...params.style,
  };

  /* Note that it is important to wrap the icon in a <span> (or a <div>, but a div
   * causes the subsequent text to be on the next line) because Material Design's

   * */

  switch (params.entryMimetype) {
    /* Word documents */
    case 'application/msword':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return (
        <span className={params.className}>
          <FaFileWord size={params.size} color="#1262B3" style={style} />
        </span>
      );

    /* Powerpoint presentations */
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return (
        <span className={params.className}>
          <FaFilePowerpoint size={params.size} style={style} />
        </span>
      );

    /* Excel spreadsheets */
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return (
        <span className={params.className}>
          <FaFileExcel size={params.size} style={style} />
        </span>
      );

    /* Emails */
    case 'application/vnd.ms-outlook':
      return (
        <span className={params.className}>
          <FiMail size={params.size} style={style} />
        </span>
      );

    /* PDF documents */
    case 'application/pdf':
      return (
        <span className={params.className}>
          <FaFilePdf size={params.size} color="#660600" style={style} />
        </span>
      );

    /* HTML documents */
    case 'text/html':
      return (
        <span className={params.className}>
          <FaFileAlt size={params.size} color="#003366" style={style} />
        </span>
      );

    /* Text documents */
    case 'text/plain':
      return (
        <span className={params.className}>
          <FaFileAlt size={params.size} color="#003366" style={style} />
        </span>
      );

    /* RTF documents */
    case 'application/rtf':
    case 'text/rtf':
      return (
        <span className={params.className}>
          <FaFileAlt size={params.size} color="#003366" style={style} />
        </span>
      );

    /* Images */
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
      return (
        <span className={params.className}>
          <FaFileImage size={params.size} color="#003366" style={style} />
        </span>
      );

    /* Directories */
    case 'inode/directory':
      return (
        <span className={params.className}>
          <FolderOpenIcon style={style} />
        </span>
      );

    /* Cases */
    case 'inode/case':
      return <WorkOutlineIcon style={style} />;

    /* Codefy annotation taglists */
    case 'application/codefy.annotation-taglist':
      return (
        <span className={params.className}>
          <Box mb={-0.5}>
            <AnnotationTaglistIcon />
          </Box>
        </span>
      );

    /* Codefy entry taglists */
    case 'application/codefy.entry-taglist':
      return (
        <span className={params.className}>
          <Box mb={-0.5}>
            <EntryTaglistIcon />
          </Box>
        </span>
      );

    /* All other files have a question mark icon */
    default:
      return (
        <span className={params.className}>
          <Box mb={-0.5}>
            <ErrorOutlineIcon />
          </Box>
        </span>
      );
  }
}
