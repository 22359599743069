import useCustomAttributesFacets from './facets/useCustomAttributesFacets';
import useLanguageFacet from './facets/useLanguageFacet';
import { useSearchFacets } from '../../../../../controllers/api/subscriptions/search/searchFacets';

export default function useFacets(facets_key?: string) {
  const { data: facets } = useSearchFacets({ facets_key });

  let facetmenuEntries = [];

  // 2021-09-25 Commented out for now since nobody really uses this and it clutters the UI
  // const recursiveFacet = useRecursiveFacet();
  // facetmenuEntries.push(recursiveFacet);

  const languageFacet = useLanguageFacet(facets);
  const customAttributesFacets = useCustomAttributesFacets(facets);

  if (languageFacet) facetmenuEntries.push(languageFacet);
  if (customAttributesFacets.length > 0) {
    facetmenuEntries = [...facetmenuEntries, ...customAttributesFacets];
  }

  if (!facets_key) return [];
  if (!(facets?.num_parts !== 0 && facets?.num_parts && facets?.num_parts > 0)) return [];

  // TODO: Add narrow down button

  return facetmenuEntries;
}
