import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { languages, useStyles } from '../index';

import Codefy from '../../../../../codefy';
import { E2E } from '../../../../../testing/common/data-e2e-ids';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { handleActionError } from '../../../../../controllers/api/actions/actionHelpers';
import { langMomentMapping } from '../../../../../i18n/localeMappings';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userChangeLanguage } from '../../../../../controllers/api/actions/user/userChangeLanguage';
import { userGet } from '../../../../../controllers/api/actions/user/userGet';

// eslint-disable-next-line react/display-name
export const LanguageSettingContent = forwardRef(
  (
    props: {
      language: Codefy.Objects.LanguageType;
      setSaveDisabled: any;
      setLoading: any;
    },
    ref: any,
  ): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();

    const [lang, setLang] = React.useState(props.language);

    // The component instance (ref) gets extended
    // with a function returned from the callback
    // passed as the second argument
    useImperativeHandle(ref, () => ({
      saveData() {
        props.setLoading(ref, true);
        // submit data
        dispatch(
          userChangeLanguage({
            language: lang,
          }),
        )
          // @ts-ignore
          .then(() => {
            // update user information on setting view
            dispatch(userGet());

            props.setLoading(ref, false);
            // change moment language
            moment.locale(langMomentMapping[lang]);
          }) // @ts-ignore
          .catch((error: any) => {
            props.setLoading(ref, false);
            handleActionError(error?.response?.data);
          });
      },
    }));

    useEffect(() => {
      // enable save if different language selected
      if (lang !== props.language) {
        props.setSaveDisabled(ref, false);
      } else {
        props.setSaveDisabled(ref, true);
      }
    });

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setLang(event.target.value as Codefy.Objects.LanguageType);
    };

    return (
      <React.Fragment>
        <Typography className={classes.secondaryHeading} variant="caption">
          {t('account.languageDescription')}
        </Typography>
        <br />
        <br />
        <FormControl className={classes.formControl}>
          <Select
            data-e2e-id={E2E.account_change_language_select}
            fullWidth
            labelId="select-helper-label"
            id="select-helper"
            value={lang}
            style={{ zIndex: 1000000 }}
            onChange={handleChange}>
            {Object.entries(languages).map(([languageCode, languageName]) => (
              <MenuItem
                data-e2e-id={`change-language-select-item-${languageCode}`}
                key={languageCode}
                value={languageCode}>
                {languageName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  },
);
