import { Grid, IconButton, ListItem, ListItemText, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

import Box from '@material-ui/core/Box';
import Codefy from '../../../../codefy';
import ImageIcon from '@material-ui/icons/Image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { NO_DRAG_SCROLL_CLASSNAME } from '../../panesViewportDragScrollHandler';
import { PaneKeys } from '../paneKeys';
import PredictionResponseWrapper from '../tagInstancePredictions/tagInstancePredictionResultWrapper';
import SearchResultContextMenuWrapper from './searchResultContextMenuWrapper';
import SearchResultsImage from './searchResultsImage';
import { SnippetVariant } from '../../../../controllers/api/subscriptions/users/userSettings';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../../../globalThemeSettings';
import useHover from '../../../../hooks/useHover';
import { usePaneActions } from '../../usePaneActions';
import { useQueryParam_search_selectedResultPartId } from '../../../../controllers/useGlobalQueryParams';
import { useTranslation } from 'react-i18next';

export default function SearchResultsItem({
  searchResult,
  index,
  isInstasearchResult,
  uniqueId,
  reviewAssistantTagId,
  defaultSnippetVariant,
}: {
  searchResult: Codefy.Objects.SearchResult;
  index: number;
  isInstasearchResult: boolean;
  uniqueId: string;
  reviewAssistantTagId?: Codefy.Objects.Tag['id'];
  defaultSnippetVariant: SnippetVariant;
}) {
  const globalClasses = useGlobalStyles();
  const typographyRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [search_selectedResultPartId] = useQueryParam_search_selectedResultPartId();

  const [individualSnippetVariant, setIndividualSnippetVariant] = useState<SnippetVariant>();
  const snippetVariant = individualSnippetVariant || defaultSnippetVariant;

  /** If the user changes the variant for the entire list, also override the current manually set
   * individual snippet variant for the current result, so that the view is consistent */
  useEffect(() => {
    setIndividualSnippetVariant(defaultSnippetVariant);
  }, [defaultSnippetVariant]);

  const [hoverRef, isHovered] = useHover();

  const paneActions = usePaneActions();

  const onClick: React.MouseEventHandler<HTMLElement> = () => {
    if (isInstasearchResult) {
      dispatch({ type: 'setSearch', open: false });
    }

    paneActions.addOrUpdatePane({
      paneKey: PaneKeys.pdfViewer,
      params: {
        pdfViewer_documentId: searchResult.document.id,
        pdfViewer_page: searchResult.highlight.boxes[0].page,
        pdfViewer_boxes: JSON.stringify(searchResult.highlight.boxes),
        search_selectedResultPartId: searchResult.part_id,
      },
    });
  };

  return (
    <Box
      pt={1}
      pb={1}
      mb={reviewAssistantTagId && 5}
      className={clsx(NO_DRAG_SCROLL_CLASSNAME)}
      onClick={onClick}>
      <PredictionResponseWrapper searchResult={searchResult} assistantTagId={reviewAssistantTagId}>
        <div ref={hoverRef}>
          <ListItem
            onClick={onClick}
            dense
            button
            selected={search_selectedResultPartId === searchResult.part_id}>
            <Box width="100%">
              <Grid item md={12}>
                <ListItemText
                  primary={
                    <Typography variant="body2" className={globalClasses.heading}>
                      <Box width="100%" className={globalClasses.textLight}>
                        – {index + 1} –
                      </Box>
                      <Box width="100%">
                        {searchResult.document.path?.directory_name ||
                          searchResult.document.path?.project_name}

                        <Box
                          className={globalClasses.floatRightVisible}
                          display="inline"
                          flexWrap="nowrap">
                          {snippetVariant === 'image' ? (
                            <Tooltip title={t('snippetVariant.showText') || ''}>
                              <IconButton
                                className={clsx(!isHovered && globalClasses.hidden)}
                                size="small"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setIndividualSnippetVariant('text');
                                }}>
                                <TextFieldsIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title={t('snippetVariant.showImage') || ''}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                size="small"
                                className={clsx(!isHovered && globalClasses.hidden)}>
                                <ImageIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <SearchResultContextMenuWrapper
                            searchResult={searchResult}
                            openOnLeftClick>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              size="small"
                              className={clsx(!isHovered && globalClasses.hidden)}>
                              <MoreVertIcon />
                            </IconButton>
                          </SearchResultContextMenuWrapper>
                        </Box>
                      </Box>
                      {searchResult.document.path?.entry_name}{' '}
                      <span className={globalClasses.textLight}>
                        › {t('searchResult.page')} {searchResult.highlight.boxes[0].page}
                      </span>
                    </Typography>
                  }
                />
              </Grid>
            </Box>
          </ListItem>

          <ListItem divider={!reviewAssistantTagId} dense id={uniqueId}>
            <Box width="100%">
              <Grid>
                <Grid item md={12}>
                  <Box position="relative" width="100%">
                    {snippetVariant === 'image' ? (
                      <SearchResultsImage searchResult={searchResult} onClick={onClick} />
                    ) : (
                      <Typography
                        variant="body2"
                        align="justify"
                        ref={typographyRef}
                        className={globalClasses.text}
                        /* Otherwise the PDF viewer opens when the user wants to select some text */
                        onClick={(event) => event.stopPropagation()}
                        dangerouslySetInnerHTML={{
                          __html: searchResult.text_snippet
                            .map(({ html, ...rest }) => {
                              return `<span data-page-number="${
                                rest.page
                              }" data-snippet='${JSON.stringify(
                                rest,
                              )}' class="searchResultPart">${html.replace(
                                /* For parts that contain newlines */ '\n',
                                '<br/><br/>',
                              )}</span>`;
                            })
                            .join(''),
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </ListItem>
        </div>
      </PredictionResponseWrapper>
    </Box>
  );
}
