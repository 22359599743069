import axios, { AxiosError } from 'axios';

import Codefy from '../../../../codefy';
import { ENTRIES_LIST_KEY_TYPE } from '../../subscriptions/entries/entriesList';
import { PROJECTS_LIST_KEY_TYPE } from '../../subscriptions/projects/projectsList';
import Promise from 'bluebird';
import { ThunkDispatch } from 'redux-thunk';
import { fastApiDataSerializer } from '../../subscriptionHelpers';
import { isFileAccepted } from '../../../../components/panes/paneTypes/entriesList/directoryFileUploadWrapper';
import moment from 'moment';
import store from '../../../store';
import { throttle } from 'throttle-debounce';
import { uploadBatchEntriesListKey } from '../../subscriptions/uploadBatches/uploadBatchesListBatchEntries';
import { uploadBatchesGetKey } from '../../subscriptions/uploadBatches/uploadBatchesGet';
import { uploadBatchesListBatchesKey } from '../../subscriptions/uploadBatches/uploadBatchesListBatches';
import { v4 as uuidv4 } from 'uuid';
import { queryClient } from '../../../../App';

/* Cannot be in codefy.d.ts because import fails for some reason */
export enum DocumentStatus {
  'unprocessed' = 0,
  'processed' = 1,
  'failed' = 2,
  'unprocessed_ocr' = 3,
  /* Added be the frontend to support upload manager functionality */
  'uploading',
  'storing',
  'uploading_cancelled',
  'error_format_not_supported',
  'error_upload_failed',
  'error_quota_exceeded',
  'uploaded',
}

const getFilePath = (file: File): string =>
  //@ts-ignore
  file.path || file.webkitRelativePath;

export const getFileId = (
  file: File,
): Codefy.API.Documents.Upload.Actions.UpdateUploadStatus['fileIds'][0] =>
  `${getFilePath(file)}-${file.name}`;

export const cancelUploadBatchUpload = async (
  upload_batch_uuid: Codefy.Objects.Upload['upload_batch_uuid'],
  status?: DocumentStatus,
) => {
  const uploads = store
    .getState()
    .uploads.filter(
      (upload) =>
        upload.upload_batch_uuid === upload_batch_uuid &&
        upload.status === DocumentStatus.uploading,
    );

  uploads.forEach((upload) => upload.onCancel?.());

  store.dispatch({
    type: 'UpdateUploadStatus',
    fileIds: uploads.map((upload) => upload.fileId),
    status: status || DocumentStatus.uploading_cancelled,
  });
};

export const cancelUpload = async (
  uploadFileId: Codefy.API.Documents.Upload.Actions.UpdateUploadStatus['fileIds'][0],
) =>
  store
    .getState()
    .uploads.find(
      (upload) => upload.fileId === uploadFileId && upload.status === DocumentStatus.uploading,
    )
    ?.onCancel?.();

/** Upload a new document to the backend, and insert it into a particular project */
export const documentsUpload = (files: File[], directoryId: Codefy.Objects.Directory['id']) => {
  return async (
    dispatch: ThunkDispatch<
      Codefy.State,
      undefined,
      | Codefy.API.Documents.Upload.Actions.AddUploads
      | Codefy.API.Documents.Upload.Actions.UpdateUploadStatus
      | Codefy.API.Documents.Upload.Actions.SetPreparingUploads
    >,
  ) => {
    dispatch({ type: 'SetPreparingUploads', preparing: true });

    /* If the user is already uploading, upload one document at a time. Otherwise, upload more
    documents simultaneously to increase upload speed. */
    const concurrency =
      store.getState().uploads.filter((upload) => upload.status === DocumentStatus.uploading)
        .length > 0
        ? 1
        : 3;

    /** A cancel token to allow cancelling all uploads */
    const batchCancelToken = axios.CancelToken.source();

    /** A UUID uniquely identifying this upload batch. */
    const UPLOAD_BATCH_UUID = uuidv4();

    const mapFileToUpload = (
      file: File,
      status: Codefy.Objects.Upload['status'],
    ): Codefy.Objects.Upload => ({
      fileId: getFileId(file),
      fileName: file.name,
      fileType: file.type,
      fileSize: file.size,
      created_at: moment().format(),
      upload_batch_uuid: UPLOAD_BATCH_UUID,
      status,
      onCancel: batchCancelToken.cancel,
    });

    const refreshQueries = () => {
      queryClient.invalidateQueries(ENTRIES_LIST_KEY_TYPE);
      queryClient.invalidateQueries(PROJECTS_LIST_KEY_TYPE);

      queryClient.invalidateQueries(
        uploadBatchesListBatchesKey({ upload_batch_uuid: UPLOAD_BATCH_UUID }),
      );
      queryClient.invalidateQueries(
        uploadBatchEntriesListKey({
          upload_batch_uuid: UPLOAD_BATCH_UUID,
          document_processing_status: DocumentStatus.unprocessed,
        }),
      );
      queryClient.invalidateQueries(
        uploadBatchEntriesListKey({
          upload_batch_uuid: UPLOAD_BATCH_UUID,
          document_processing_status: DocumentStatus.processed,
        }),
      );
      queryClient.invalidateQueries(
        uploadBatchEntriesListKey({
          upload_batch_uuid: UPLOAD_BATCH_UUID,
          document_processing_status: DocumentStatus.failed,
        }),
      );
      queryClient.invalidateQueries(uploadBatchesGetKey(UPLOAD_BATCH_UUID));
    };

    const throttledRefreshQueries = throttle(
      /* Increase time before refreshing with rising file count */
      Math.min(15000, files.length * 150),
      refreshQueries,
      true,
    );

    /** Uploads a file while storing the current upload progress in the Redux store
     * so a live progress bar can be shown to the user */
    const handleFileUpload = async (file: File) => {
      const fileId = getFileId(file);

      const data = {
        directory_id: directoryId,
        file,
        path: getFilePath(file),
        upload_batch_uuid: UPLOAD_BATCH_UUID,
      };

      try {
        await axios.post<Codefy.Objects.Entry>(
          '/api/v1/documents/upload',
          fastApiDataSerializer(data),
          /* Note that "If a cancellation token is already cancelled at the moment of starting an
           Axios request, then the request is cancelled immediately, without any attempts to
           make a real request.", so we can cancel in advance */
          { cancelToken: batchCancelToken.token },
        );

        dispatch({
          type: 'UpdateUploadStatus',
          fileIds: [fileId],
          status: DocumentStatus.storing,
        });
      } catch (er) {
        const error = er as Error | AxiosError;
        console.error(error);
        if ('response' in error) {
          let status;
          switch (error.response?.status) {
            case 507:
              status = DocumentStatus.error_quota_exceeded;
              break;
            default:
              status = DocumentStatus.error_upload_failed;
          }

          dispatch({ type: 'UpdateUploadStatus', fileIds: [fileId], status });

          if (status === DocumentStatus.error_quota_exceeded) {
            cancelUploadBatchUpload(UPLOAD_BATCH_UUID, DocumentStatus.error_quota_exceeded);
            throw new Error();
          }
        }
      }
      throttledRefreshQueries();
    };

    const filesFormatNotSupported = files.filter((file) => !isFileAccepted(file));
    const filesFormatSupported = files.filter((file) => isFileAccepted(file));

    dispatch({
      type: 'AddUploads',
      uploads: filesFormatNotSupported.map((file) =>
        mapFileToUpload(file, DocumentStatus.error_format_not_supported),
      ),
    });

    dispatch({
      type: 'AddUploads',
      uploads: filesFormatSupported.map((file) => mapFileToUpload(file, DocumentStatus.uploading)),
    });

    dispatch({ type: 'SetPreparingUploads', preparing: false });

    /* Reversed so the next file being uploaded appears on the top of the uploading list and the
      user sees action */
    await Promise.map(filesFormatSupported.reverse(), handleFileUpload, { concurrency });

    refreshQueries();

    /* Set all old uploads as "uploaded", so that when the user uploads some files again, the old
        batches don't show "Storing...". Uploaded should never be shown. */
    dispatch({
      type: 'UpdateUploadStatus',
      fileIds: store
        .getState()
        .uploads.filter((upload) => upload.status === DocumentStatus.storing)
        .map((upload) => upload.fileId),
      status: DocumentStatus.uploaded,
    });
  };
};
