import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Codefy from '../../../codefy';
import { useEntriesList } from '../../../controllers/api/subscriptions/entries/entriesList';
import { useSearchAutocomplete } from '../../../controllers/api/subscriptions/search/searchAutocomplete';
import { useSearchSearch } from '../../../controllers/api/subscriptions/search/searchSearch';
import { useUserSetting_searchDropdown_snippetVariant } from '../../../controllers/api/subscriptions/users/userSettings';
import {
  useQueryParam_searchBar_directoryId,
  useQueryParam_searchBar_documentId,
} from '../../../controllers/useGlobalQueryParams';
import { COLORS, useGlobalStyles } from '../../../globalThemeSettings';
import PaneContentEmpty from '../../panes/paneContentEmpty';
import PaneContentLoading from '../../panes/paneContentLoading';
import EntryFileTypeIcon from '../../panes/paneTypes/entriesList/entryFileTypeIcon';
import { PaneKeys } from '../../panes/paneTypes/paneKeys';
import Autocomplete from '../../panes/paneTypes/search/autocomplete';
import AutocompletePlaceholder from '../../panes/paneTypes/search/autocompletePlaceholder';
import SearchResultsItem from '../../panes/paneTypes/search/searchResultsItem';
import useSearchParams from '../../panes/paneTypes/search/useSearchParams';
import { usePaneActions } from '../../panes/usePaneActions';
import { SEARCHBAR_ARROWKEY_SELECTED_ID } from './searchBar';
import { setSearchBarInputValue } from './setSearchBarInputValue';
import SnippetVariantSwitch from './snippetVariantSwitch';

const AUTOCOMPLETE_COUNT = 5;
export const ENTRIES_COUNT = 5;
export const ENTRIES_SHOW_MORE_COUNT = 100;
const RESULTS_COUNT = 10;

type ClassesParams = { width: number; open?: boolean };

const useStyles = makeStyles<any, ClassesParams>({
  root: {
    display: ({ open }: ClassesParams) => (open ? 'initial' : 'none'),
    position: 'fixed',
    top: '50px',
    left: '220px',
    width: ({ width }: ClassesParams) => width + 350,
    maxWidth: `calc(95vw - 220px)`,
    overflowY: 'auto',
    maxHeight: '90vh',
    backgroundColor: 'white',
  },
});

export default function SearchDropdown({ width }: { width: number }) {
  const { t } = useTranslation();
  const [searchBar_directoryId] = useQueryParam_searchBar_directoryId();
  const [searchBar_documentId] = useQueryParam_searchBar_documentId();
  const searchOpen = useSelector((state: Codefy.State) => state.search.open);
  const searchQuery = useSelector((state: Codefy.State) => state.search.query);
  const searchbarArrowKeyPosition = useSelector(
    (state: Codefy.State) => state.search.searchbarArrowKeyPosition,
  );
  const paneActions = usePaneActions();
  const dispatch = useDispatch();

  const [
    userSetting_searchDropdown_snippetVariant,
    set_userSetting_searchDropdown_snippetVariant,
  ] = useUserSetting_searchDropdown_snippetVariant();

  /** Has the user clicked on the "show more" button under the entries? */
  const [showMoreEntriesClicked, setShowMoreEntriesClicked] = useState<boolean>(false);

  const searchParamsWhileTyping = useSearchParams({ variant: 'searchBar' });

  const {
    data: searchPages,
    isFetching: searchFetching,
    isFetched: searchFetched,
  } = useSearchSearch(
    {
      ...searchParamsWhileTyping,
      limit: RESULTS_COUNT,
    },
    true,
  );

  const onOpenSearchPane = () => {
    if (searchQuery) {
      paneActions.addOrUpdatePane({
        paneKey: PaneKeys.search,
        params: {
          search_method: 'insta',
          search_query: searchQuery,
          search_isAutocomplete: 0,
          search_directoryId: searchBar_directoryId,
          search_documentId: searchBar_documentId,
        },
      });
    }

    dispatch({ type: 'setSearch', open: false });
  };

  const {
    data: autocomplete,
    isLoading: autocompleteLoading,
    isFetching: autocompleteFetching,
    isFetched: autocompleteFetched,
  } = useSearchAutocomplete({
    ...searchParamsWhileTyping,
    limit: AUTOCOMPLETE_COUNT,
  });

  const {
    data: entriesPages,
    isFetching: entriesFetching,
    isFetched: entriesFetched,
  } = useEntriesList({
    directory_ids: searchParamsWhileTyping.directory_ids,
    name: searchParamsWhileTyping.query,
    limit: showMoreEntriesClicked ? ENTRIES_SHOW_MORE_COUNT : ENTRIES_COUNT,
    descending: true,
    recursive: true,
  });

  const results = searchPages?.pages[0].results || [];
  const entries = entriesPages?.pages[0].entries || [];

  const classes = useStyles({
    width,
    open: searchOpen,
  });
  const globalClasses = useGlobalStyles();

  const dontClose: React.MouseEventHandler<HTMLElement> = useCallback((event) => {
    event.stopPropagation();
  }, []);

  let autocompleteEntries;
  /** Autocomplete placeholders should only be shown at the very beginning, to fill the
   * void. Later, when the user continues to type, it should just wait for the new
   * results, because in that case showing placeholders would increase the perceived
   * delay. */
  if (autocompleteLoading && !autocomplete?.results && searchQuery !== '') {
    autocompleteEntries = [0, 1, 2, 3, 4].map((index) => <AutocompletePlaceholder key={index} />);
  } else if (autocomplete?.results && autocomplete?.results?.length > 0) {
    autocompleteEntries = autocomplete?.results?.map((autocomplete) => (
      <Autocomplete key={autocomplete.text} autocomplete={autocomplete} />
    ));
  }

  try {
    if (autocomplete?.debug) {
      console.debug(
        'QUERY DEBUG – AUTOCOMPLETE',
        /** the split is so that is easier to read in the browser console */
        autocomplete.debug.map((e: string) => e.split('\n')),
      );
    }

    if (searchPages?.pages[0]?.debug) {
      console.debug(
        'QUERY DEBUG – INSTASEARCH',
        searchPages?.pages[0]?.debug.map((e: string) => e.split('\n')),
      );
    }
  } catch (error) {
    console.error(error);
  }

  const renderEntry = (entry: Codefy.Objects.Entry, index: number) => {
    const selected = index + 1 === searchbarArrowKeyPosition;
    return (
      <ListItem
        key={'overlay-entry-search-by-name-' + entry.id}
        button
        dense
        selected={selected}
        id={selected ? SEARCHBAR_ARROWKEY_SELECTED_ID : ''}
        onClick={() => {
          if (entry.document) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.document.path.directory_id },
              reset: true,
            });
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.pdfViewer,
              params: { pdfViewer_documentId: entry.document.id },
              reset: true,
            });
          }
          if (entry.directory) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.directory.id },
            });
          }
          if (entry.taglist) {
            paneActions.addOrUpdatePane({
              paneKey: PaneKeys.entriesList,
              params: { entriesList_directoryId: entry.taglist.path.directory_id },
              reset: true,
            });
            if (entry.taglist.type === 'annotation') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.annotationTaglist,
                params: { annotationTaglist_id: entry.taglist.id },
              });
            } else if (entry.taglist.type === 'entry') {
              paneActions.addOrUpdatePane({
                paneKey: PaneKeys.entryTaglist,
                params: { entryTaglist_id: entry.taglist.id },
              });
            }
          }
          dispatch({ type: 'setSearch', open: false, query: '' });
          setSearchBarInputValue('');
          setShowMoreEntriesClicked(false);
        }}>
        <ListItemIcon className={globalClasses.narrowListItemIcon}>
          {<EntryFileTypeIcon entryMimetype={entry.mimetype} style={{ marginRight: '3px' }} />}
        </ListItemIcon>
        <ListItemText
          primary={
            <Highlighter
              searchWords={searchQuery.split(' ')}
              autoEscape={true}
              textToHighlight={entry.name}
              unhighlightStyle={{ fontWeight: 'bold' }}
              highlightStyle={{
                backgroundColor: COLORS.textHighlightBackground,
                color: COLORS.primary,
                fontWeight: 'bold',
              }}></Highlighter>
          }
        />
      </ListItem>
    );
  };

  if (!searchQuery) return null;

  if (
    results &&
    results.length === 0 &&
    autocompleteFetched &&
    autocomplete &&
    autocomplete?.results.length === 0 &&
    searchFetched &&
    results?.length === 0 &&
    entriesFetched &&
    entries.length === 0
  ) {
    return (
      <div className={clsx(globalClasses.uiElementHigh, classes.root)}>
        <PaneContentEmpty text={t('searchBar.noResults')} />
      </div>
    );
  }

  if (showMoreEntriesClicked) {
    return (
      <div className={clsx(globalClasses.uiElementHigh, classes.root)}>
        <List dense>
          <ListItem
            button
            onClick={() => {
              setShowMoreEntriesClicked(false);
            }}>
            <ListItemText
              primary={
                <Typography className={globalClasses.link}>
                  {t('searchBar.goBackToAllResults')}
                </Typography>
              }></ListItemText>
          </ListItem>
          <ListItem dense>
            <Typography className={globalClasses.subheading}>
              {t('searchBar.documentsAndFolders')}
            </Typography>
          </ListItem>
          {entries?.map(renderEntry)}
          {entriesPages?.pages[0].num_entries &&
            entriesPages?.pages[0].num_entries > ENTRIES_SHOW_MORE_COUNT && (
              <ListItem button>
                <ListItemText
                  primary={
                    <Typography className={globalClasses.link}>
                      {t('searchBar.showingXResultsOnly', { count: ENTRIES_SHOW_MORE_COUNT })}
                    </Typography>
                  }></ListItemText>
              </ListItem>
            )}
        </List>
      </div>
    );
  }

  return (
    <div className={clsx(globalClasses.uiElementHigh, classes.root)}>
      <div
        className={clsx(
          !(searchFetching || autocompleteFetching || entriesFetching) && globalClasses.invisible,
        )}>
        <PaneContentLoading />
      </div>

      <Grid container>
        <Grid item md={6}>
          <List dense>
            <ListItem dense>
              <Typography className={globalClasses.subheading}>
                {t('searchBar.documentsAndFolders')}
              </Typography>
            </ListItem>
            {entries.map(renderEntry)}
            {!!entriesPages?.pages[0].num_entries &&
              entriesPages?.pages[0].num_entries > ENTRIES_COUNT && (
                <ListItem
                  button
                  onClick={() => {
                    setShowMoreEntriesClicked(true);
                  }}>
                  <ListItemText
                    primary={
                      <Typography className={globalClasses.link}>
                        {t('searchBar.showMore')}
                      </Typography>
                    }></ListItemText>
                </ListItem>
              )}
          </List>
        </Grid>

        {autocompleteEntries && autocompleteEntries?.length > 0 && (
          <>
            <Grid item md={6} style={{ borderLeft: `1px solid ${COLORS.uiSkeleton}` }}>
              <List dense>
                <ListItem dense>
                  <Typography className={globalClasses.subheading}>
                    {t('searchBar.searchSuggestions')}
                  </Typography>
                </ListItem>
                {autocompleteEntries}
              </List>
            </Grid>
          </>
        )}
      </Grid>

      {results.length > 0 && (
        <Box onClick={dontClose}>
          <ListItem
            dense
            style={{ borderTop: `1px solid ${COLORS.uiSkeleton}`, paddingTop: '20px' }}>
            <Typography className={globalClasses.subheading} noWrap>
              <Grid container wrap="nowrap">
                <Grid item>{t('searchBar.content')}</Grid>
                <Grid item>
                  <Box mt={-1}>
                    <SnippetVariantSwitch
                      value={userSetting_searchDropdown_snippetVariant || 'image'}
                      onChange={set_userSetting_searchDropdown_snippetVariant}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Typography>
          </ListItem>

          {results?.map((searchResult, index) => (
            <SearchResultsItem
              searchResult={searchResult}
              index={index}
              key={'overlay-document-' + searchResult.document.id + 'part-' + searchResult.part_id}
              uniqueId={
                'overlay-document-' + searchResult.document.id + 'part-' + searchResult.part_id
              }
              isInstasearchResult={true}
              defaultSnippetVariant={userSetting_searchDropdown_snippetVariant || 'image'}
            />
          ))}
        </Box>
      )}
      {/* If we get 10 out of 10 results, we assume there are more. Worst case the user open the
      results in the search pane and sees the same 10 results. Oh well. */}
      {results.length === RESULTS_COUNT && (
        <ListItem button onClick={onOpenSearchPane}>
          <ListItemText
            primary={
              <Typography className={globalClasses.link}>{t('searchBar.showAll')}</Typography>
            }></ListItemText>
        </ListItem>
      )}
    </div>
  );
}
