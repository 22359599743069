import {
  BORDER_RADIUSES,
  COLORS,
  componentTypeStyles,
  useGlobalStyles,
} from '../../globalThemeSettings';
import { Box, Grid, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useIsPaneCollapsed,
  useQueryParam_focusedPane,
  useQueryParam_panes,
  useQueryParam_trashMode,
  useQueryParams_collapsedPanes,
} from '../../controllers/useGlobalQueryParams';
import {
  usePaneConnectedToTheLeft,
  usePaneConnectedToTheRight,
  usePaneFocusedSinceConnectedPaneIsFocused,
} from './usePaneConnected';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import GenericMenuButton from '../menus/genericMenuButton';
import { GenericNestableMenuEntry } from '../menus/genericNestableMenuEntry';
import { PaneKeys } from './paneTypes/paneKeys';
import SearchIcon from '../icons/search';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { getPaneE2eId } from '../../testing/common/data-e2e-ids';
import { paneTypes } from './paneTypes/paneTypes';
import { useCopyToClipboard } from 'react-use';
import useHover from '../../hooks/useHover';
import { usePaneActions } from './usePaneActions';
import { useTranslation } from 'react-i18next';

export const PaneMenuButtonProps = {
  size: 'small',
  variant: 'outlined',
} as const;

export const PaneMenuButtonGroupProps = {
  style: { marginRight: '5px', display: 'flex', flex: '0 0 auto' },
} as const;

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    position: 'relative',
    paddingLeft: '6px',
    paddingRight: '6px',
    height: '60px',
    cursor: 'pointer',
    borderTopLeftRadius: BORDER_RADIUSES.small,
    borderTopRightRadius: BORDER_RADIUSES.small,
    borderBottom: `1px solid ${COLORS.uiSkeletonLight}`,
    backgroundColor: COLORS.backgroundLight,
  },
  connectedToTheLeft: {
    borderTopLeftRadius: '0px',
  },
  connectedToTheRight: {
    borderTopRightRadius: '0px',
  },
  focused: {
    background: COLORS.primaryLight,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    marginLeft: '10px',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    ...componentTypeStyles.heading,
  },
  titleB: {
    marginLeft: '10px',
  },
  windowButton: {
    maxWidth: '50px',
    maxHeight: '50px',
  },
  error: {
    color: '#f44336',
    fontWeight: 'bold',
  },
  errorIcon: {
    marginRight: '3px',
  },
  moreButton: { width: '30px', height: '30px', marginLeft: '3px' },
  paneEntriesGrid: {
    height: '35px',
    backgroundColor: COLORS.backgroundLight,
    borderBottom: `1px solid ${COLORS.uiSkeletonLight}`,
    paddingLeft: '8px',
    paddingRight: '8px',
  },
}));

function ToggleCollapsePaneButton({ paneKey }: { paneKey: PaneKeys }) {
  const classes = useStyles();
  const [collapsedPanes] = useQueryParams_collapsedPanes();
  const paneActions = usePaneActions();
  const paneIsCollapsed = collapsedPanes?.includes(paneKey);

  const onToggle = () => {
    paneActions.toggleCollapsePane(paneKey);
  };

  return (
    <Box mr={1}>
      <IconButton color="inherit" onClick={onToggle} className={classes.windowButton} size="small">
        {paneIsCollapsed ? (
          <ExpandLessIcon color="primary" className={classes.windowButton} />
        ) : (
          <ChevronLeftIcon color="primary" className={classes.windowButton} />
        )}
      </IconButton>
    </Box>
  );
}

/** A generic component that all the panes use to display their title bar (the stuff above the
 * content in a unified way) */
export default function PaneTitleBar({
  paneKey,
  icon,
  title,
  titleCopyable,
  menuEntries,
  statusBar,
  loading,
  isError,
  onSearchCallback,
  onSearchCallbackText,
  tagInstancePredictionsMenuEntry,
  commentsMenuEntry,
  annotationsMenuEntry,
  onRenameCallback,
  onCollapseRightPane,
}: {
  paneKey: PaneKeys;
  /** An icon that is displayed to the left of the title */
  icon?: React.ReactElement;
  /** A title that is displayed in the first line */
  title?: string | React.ReactElement;
  /** If true, clicking on the title copies it to the clipboard */
  titleCopyable?: boolean;
  /** Additional menu entries behind the "more" icon */
  menuEntries?: GenericNestableMenuEntry[];
  /** If no menu entries, optionally show a status bar */
  statusBar?: string | React.ReactElement;
  /** Display a loading indicator over the entire title bar */
  loading?: boolean;
  /** If true, the pane will be closed */
  isError?: boolean;
  /** Executed when the user presses the Search icon */
  onSearchCallback?: (event?: any) => any;
  onSearchCallbackText?: string;
  /** Executed when the user presses the review assistant icon */
  tagInstancePredictionsMenuEntry?: GenericNestableMenuEntry;
  /** Executed when the user presses the show comments icon */
  commentsMenuEntry?: GenericNestableMenuEntry;
  /** Executed when the user presses the annotations icon */
  annotationsMenuEntry?: GenericNestableMenuEntry;
  /** Executed when the user presses the rename icon */
  onRenameCallback?: (event?: any) => any;
  onCollapseRightPane?: () => any;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const paneActions = usePaneActions();
  const [panes] = useQueryParam_panes();
  const [focusedPane] = useQueryParam_focusedPane();
  const [, copyToClipboard] = useCopyToClipboard();
  const [userHasCopiedTitle, setUserHasCopiedTitle] = useState<boolean>(false);
  const [trashMode] = useQueryParam_trashMode();

  const [hoverRef, isHovered] = useHover();

  const paneType = paneTypes.find((p) => p.key === paneKey);

  const paneIsCollapsed = useIsPaneCollapsed(paneKey);
  const paneConnectedToTheLeft = usePaneConnectedToTheLeft(paneKey);
  const paneConnectedToTheRight = usePaneConnectedToTheRight(paneKey);
  const paneFocusedSinceConnectedPaneIsFocused = usePaneFocusedSinceConnectedPaneIsFocused(paneKey);

  const onlyOnePaneOpen = panes?.length === 1;

  const onClosePane = () => paneActions.closePanes([paneKey]);
  /* If the pane has an error. Close it. The most common usecase is to "garbage collect" (= close)
  panes that point to objects that have been deleted. */
  useEffect(() => {
    if (!isError) return;
    onClosePane();
  }, [isError]);

  const additionalMenuEntries: GenericNestableMenuEntry[] = [];
  if (onSearchCallback) {
    additionalMenuEntries?.push({
      key: 'search',
      text: onSearchCallbackText || t('paneTitleBar.search'),
      icon: <SearchIcon />,
      onClick: onSearchCallback,
    });
  }
  if (commentsMenuEntry) {
    additionalMenuEntries?.push(commentsMenuEntry);
  }
  if (annotationsMenuEntry) {
    additionalMenuEntries?.push(annotationsMenuEntry);
  }
  if (tagInstancePredictionsMenuEntry) {
    additionalMenuEntries?.push(tagInstancePredictionsMenuEntry);
  }

  /** Executed when the title is set as clickable and the user clicks on it */
  const onCopyTitle = () => {
    if (title && titleCopyable && typeof title === 'string') {
      copyToClipboard(title);
      setUserHasCopiedTitle(true);
    }
  };

  /** Tooltip displayed when hovering over title that is set as clickable */
  const copyTitleTooltipText =
    title && titleCopyable && typeof title === 'string'
      ? userHasCopiedTitle
        ? t('paneTitleBar.copied')
        : t('paneTitleBar.clickToCopy')
      : /** Empty string disables tooltip. Neat! */
        '';

  // TODO: This component might need refactoring or at least some tests

  const finalMenuEntries = [...(menuEntries || []), ...additionalMenuEntries];

  return (
    <div ref={hoverRef} className={clsx(trashMode && globalClasses.trashInactive)}>
      <Grid
        wrap="nowrap"
        container
        alignItems="center"
        className={clsx(
          classes.root,
          (focusedPane === paneKey || paneFocusedSinceConnectedPaneIsFocused) && classes.focused,
          paneConnectedToTheLeft && classes.connectedToTheLeft,
          paneConnectedToTheRight && classes.connectedToTheRight,
        )}>
        {loading ? (
          <Skeleton variant="rect" width="100%" />
        ) : (
          <>
            <Typography className={classes.title} noWrap component="div">
              <Grid container direction="row" alignItems="center" wrap="nowrap">
                {icon}{' '}
                <span className={icon && classes.titleB}>
                  <Tooltip
                    title={paneIsCollapsed ? '' : copyTitleTooltipText}
                    onClick={paneIsCollapsed ? undefined : onCopyTitle}>
                    <Typography noWrap className={globalClasses.heading}>
                      {title}
                    </Typography>
                  </Tooltip>
                </span>
                {onRenameCallback && isHovered && !paneIsCollapsed && (
                  <Box ml={0.5}>
                    <IconButton size="small">
                      <EditIcon onClick={onRenameCallback} fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Grid>
            </Typography>
            <div className={classes.grow} />
          </>
        )}
        {(isHovered || paneIsCollapsed) && <ToggleCollapsePaneButton paneKey={paneKey} />}

        {!onlyOnePaneOpen &&
        /** Don't show a close button if the pane is the left pane of a connected pane pair,
         * because connected panes are both closed if one of them is closed anyways, so we just
         * need one close button **/
        !paneConnectedToTheRight &&
        paneType?.closable !== false &&
        !trashMode ? (
          <Box mr={1} data-e2e-id={getPaneE2eId(paneKey, 'closeButton')}>
            <IconButton
              color="inherit"
              onClick={onClosePane}
              className={classes.windowButton}
              size="small">
              <CloseIcon color="primary" className={classes.windowButton} />
            </IconButton>
          </Box>
        ) : onCollapseRightPane ? (
          <span />
        ) : // <Box mr={1} data-e2e-id={getPaneE2eId(paneKey, 'closeButton')}>
        //   <IconButton
        //     color="inherit"
        //     onClick={onCollapseRightPane}
        //     className={classes.windowButton}
        //     size="small">
        //     <ArrowLeftIcon color="primary" className={classes.windowButton} />
        //   </IconButton>
        // </Box>
        null}
      </Grid>

      {!paneIsCollapsed && (
        <div className={classes.paneEntriesGrid}>
          <div className={clsx(trashMode && globalClasses.trashInactiveTransparent)}>
            <Grid container alignItems="center" wrap="nowrap">
              {statusBar && (
                <Box width="100%" m={1}>
                  {statusBar}
                </Box>
              )}{' '}
              {finalMenuEntries
                ?.filter((menuEntry) => !menuEntry.disabled)
                .map((menuEntry) => (
                  <PaneMenuBarEntry key={menuEntry.key} menuEntry={menuEntry} />
                ))}
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
}

function PaneMenuBarEntrySelectedWrapper({
  selected,
  children,
}: {
  selected: boolean;
  children: React.ReactNode;
}) {
  if (!selected) return <>{children}</>;
  return (
    <Box>
      {children}
      <div
        style={{
          position: 'relative',
          bottom: -9,
          right: 5,
          display: 'inline-block',
          transform: 'rotate(45deg)',
          height: '12px',
          width: '6px',
          borderBottom: '2px solid ' + COLORS.primary,
          borderRight: '2px solid ' + COLORS.primary,
        }}
      />
    </Box>
  );
}

function PaneMenuBarEntry({ menuEntry }: { menuEntry: GenericNestableMenuEntry }) {
  const globalClasses = useGlobalStyles();

  const button = useMemo(
    () => (
      <Tooltip title={typeof menuEntry.text === 'string' ? menuEntry.text : ''} enterDelay={1000}>
        <Box key={menuEntry.key} ml={0.5} mr={0.5}>
          {/** The badge allows us to show if e.g. a filter is currently activated, so the user has
           * some feedback and will not wonder "huh, why are most of my entries gone?" */}
          <PaneMenuBarEntrySelectedWrapper selected={!!menuEntry.selected}>
            {menuEntry.inactive ? (
              menuEntry.icon
            ) : (
              <IconButton
                size="small"
                disabled={menuEntry.disabled}
                onClick={() => menuEntry.onClick?.()}
                data-e2e-id={menuEntry.dataE2eId}>
                {menuEntry.icon}
              </IconButton>
            )}
          </PaneMenuBarEntrySelectedWrapper>
        </Box>
      </Tooltip>
    ),
    [menuEntry.disabled, !!menuEntry.onClick, menuEntry.icon, menuEntry.key, menuEntry.text],
  );

  if (menuEntry.divider) return <div className={globalClasses.verticalIconDivider} />;

  return menuEntry.menuEntries ? (
    <GenericMenuButton
      button={button}
      menuEntries={menuEntry.menuEntries}
      title={menuEntry.text}
      hideMenuEntriesGroupTitle={menuEntry.hideMenuEntriesGroupTitle}
    />
  ) : (
    button
  );
}
